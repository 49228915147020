import React, { ReactElement } from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import { LaLogoBrand } from "@labarchives/ui-design";
import { useIntl } from "react-intl";
import { ErrorBoundary } from "../components/ErrorBoundary/ErrorBoundary";
import { Settings } from "../utils/Settings";
import { ApplicationPaths } from "./ApplicationPaths";

export function SessionExpiredApp(): ReactElement {
  const intl = useIntl();
  const currentUrl = [window.location.protocol, "//", window.location.host, window.location.pathname].join("");
  const returnTo = encodeURI(currentUrl.replace(ApplicationPaths.Logout, ""));
  const href = `${Settings.getAccountUrlBase()}/login?return=${returnTo}`;

  return (
    <ErrorBoundary>
      <div>
        <header>
          <a href="#main-content" className="skip-main">
            {intl.formatMessage({ id: "skip.to.main.content" })}
          </a>

          <Navbar dark expand="lg" fixed="top" id={"primary-navigation"} role={"navigation"} aria-label={"Main"}>
            <NavbarBrand
              href={`${Settings.getAccountUrlBase()}/login?return=${returnTo}`}
              aria-label={intl.formatMessage({ id: "nav.inventory.home" })}
            >
              <LaLogoBrand className="logo" rightAlignText>
                <span className="appName">Inventory</span>
              </LaLogoBrand>
            </NavbarBrand>
          </Navbar>
        </header>
        <main className="container-fluid" id={"main-content"} role={"main"}>
          <div className="session-expired-card">
            <h2>Session ended</h2>
            <div className={"session-expired-card-body"}>
              <div>{intl.formatMessage({ id: "session.ended.message" })}</div>
              <div className={"session-expired-button"}>
                <a href={href} className={"btn btn-orange"}>
                  {intl.formatMessage({ id: "go.to.sign.in.page" })}
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </ErrorBoundary>
  );
}
