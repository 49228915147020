import React, { ReactElement } from "react";
import { Route, Routes } from "react-router";
import { Col, Row } from "reactstrap";
import { UsersProvider } from "../user/UsersProvider";
import { MaintenanceNotice } from "../components/MaintenanceNotice/maintenanceNotice";
import { InventorySiteAdminSearch } from "../inventorySiteAdmin/inventory/search/InventorySearch";
import { AdminViewInventory } from "../inventorySiteAdmin/inventory/AdminViewInventory";
import { NotFound } from "../notfound/NotFound";
import { InventoryAdminNavigation } from "../components/NavBar/AdminNavigation";
import { Logout } from "../components/Authentication/Logout";
import { AdminViewManagement } from "../inventorySiteAdmin/management/AdminViewManagement";
import { SessionTimeoutModal } from "../components/Authentication/SessionTimeoutModal";
import { AdminViewManageLocations } from "../inventorySiteAdmin/management/AdminViewManageLocations";
import { ApplicationPaths } from "./ApplicationPaths";

export function InventoryAdminApp(): ReactElement {
  return (
    <UsersProvider>
      <InventoryAdminNavigation />
      <MaintenanceNotice />
      <main className="container-fluid" id={"main-content"} role={"main"}>
        <Row>
          <Col>
            <Routes>
              <Route path={ApplicationPaths.Routes.Home} element={<InventorySiteAdminSearch />} />
              <Route path={ApplicationPaths.Admin.Routes.InventorySearch} element={<InventorySiteAdminSearch />} />
              <Route path={ApplicationPaths.Admin.Routes.InventoryItem} element={<AdminViewInventory />} />
              <Route path={ApplicationPaths.Admin.Routes.Management.Home} element={<AdminViewManagement />}>
                <Route index path={"locations"} element={<AdminViewManageLocations />} />
              </Route>
              <Route path={ApplicationPaths.Routes.Logout} element={<Logout />} />
              <Route path={"*"} element={<NotFound />} />
            </Routes>
          </Col>
        </Row>
        <SessionTimeoutModal />
      </main>
    </UsersProvider>
  );
}
