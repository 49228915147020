/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { ButtonGroup, Col, Form, Row } from "reactstrap";
import { Button, Card, CardBody } from "@labarchives/ui-design";
import { FormattedMessage, useIntl } from "react-intl";
import { faChevronDown, faFilter, faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router";
import { RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { InventoryImportModal } from "../import/InventoryImportModal";
import { InventoryExportModal } from "../import/InventoryExportModal";
import { getFormattedFreezerBoxCells } from "../../storage/selectors";
import { UserState } from "../../user/types/state";
import { UsersContext } from "../../user/UsersContext";
import { InventoryTypesContext } from "../../inventorytypes/InventoryTypesContext";
import { StorageContext } from "../../storage/StorageContext";
import { RoleState } from "../../roles/types/state";
import { RolesContext } from "../../roles/RolesContext";
import { AuthorizedComponent } from "../../components/Authentication/AuthorizedComponent";
import { AuthenticationContext } from "../../components/Authentication/AuthenticationContext";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import { VendorsContext } from "../../vendors/VendorsContext";
import "./InventorySearch.scss";
import { UseNowModal } from "../UseNowModal/UseNowModal";
import { onPageLoad } from "../../utils/onPageLoad";
import { InventoryApiClient } from "../../api";
import { useInventorySearch } from "./InventorySearchHooks";
import { InventoryAdvancedSearch } from "./advanced/InventoryAdvancedSearch";
import { useSearchResultsUseNow } from "./results/InventorySearchResultsUseNowHooks";
import { InventorySearchAutoSuggest } from "./autosuggest/InventorySearchAutoSuggest";
import { InventorySearchResults } from "./results/InventorySearchResults";
import { InventorySearchFilter } from "./filter/InventorySearchFilter";

export function InventorySearch(): ReactElement {
  const [isShowingFilter, setIsShowingFilter] = useState(false);
  const intl = useIntl();
  const api = new InventoryApiClient();

  const userState = useContext<UserState>(UsersContext);
  const {
    isLoading,
    isShowingImport,
    isShowingExport,
    isAdvancedSearchBoxShown,
    advancedSearchCriteria,
    searchResults,
    columnOrder,
    currency,
    searchTerm,
    filters,
    userViews,
    searchCriteria,
    inventoryTypeViews,
    storageLocations,
    accountName,
    onResultsOptionsChanged,
    onFilterChanged,
    onFilterCleared,
    onImportModalToggled,
    onExportModalToggled,
    onImportStarted,
    onSearchOnNewTerm,
    onSearchTermChange,
    onClearSearchTerm,
    onAdvancedSearchToggle,
    onAdvancedSearch,
    onUseNow,
    isUsed,
    onInventoryStorageChanged,
  } = useInventorySearch(
    userState,
    useContext<RoleState>(RolesContext),
    useContext(InventoryTypesContext),
    useContext(StorageContext),
    useContext(VendorsContext),
    useContext(AuthenticationContext),
    {
      advancedCriteria: userState.lastInventoryAdvancedCriteria,
      searchCriteria: userState.lastInventoryFilter,
      onCriteriaChanged: userState.updateLastInventorySearchCriteria,
    },
    api,
  );

  const { onUseNowOpened, useNowProps } = useSearchResultsUseNow({ onUseNow, api });

  const [isShowingSaveAdvancedSearch, setIsShowingSaveAdvancedSearch] = useState(false);

  function onSaveSearchToggle(): void {
    setIsShowingSaveAdvancedSearch((prev) => !prev);
  }

  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.inventory.search" }));
  }, []);

  return (
    <>
      <Row>
        <Col xl={2} role={"region"} aria-label={intl.formatMessage({ id: "filters" })}>
          <InventorySearchFilter
            inventoryTypeViews={inventoryTypeViews}
            storageLocations={storageLocations.asTree()}
            filters={filters}
            onFilterChanged={onFilterChanged}
            onFilterCleared={onFilterCleared}
            isShowingFilter={isShowingFilter}
            onFilterToggle={() => setIsShowingFilter(!isShowingFilter)}
          />
        </Col>
        <Col xl={10} role={"region"} aria-labelledby={"inventory-search-heading"}>
          <Card className="inventory-search-primary-content">
            <CardBody>
              <Row>
                <Col xs={12} md={4}>
                  <div>
                    <h1 className="inventory-heading" id={"inventory-search-heading"}>
                      <FormattedMessage id="inventory.search" />
                    </h1>
                    {accountName && (
                      <div className={"inventory-search-lab-name"} title={accountName}>
                        {accountName}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={12} md={8}>
                  <div style={{ position: "relative" }}>
                    <div>
                      <div id="inventory-search-text-container" className={`${accountName ? "with-account-name" : ""}`}>
                        <InventoryAdvancedSearch
                          isOpen={isAdvancedSearchBoxShown}
                          onSearch={onAdvancedSearch}
                          inventoryTypeViews={inventoryTypeViews}
                          selectedInventoryTypes={inventoryTypeViews.filter((v) => filters.typeIds.includes(v.id))}
                          onClosed={onAdvancedSearchToggle}
                          criteria={advancedSearchCriteria}
                          onSaveSearchToggle={onSaveSearchToggle}
                          isShowingSaveAdvancedSearch={isShowingSaveAdvancedSearch}
                          api={api}
                        />
                        {advancedSearchCriteria.length > 0 && (
                          <div style={{ textAlign: "right", flexGrow: 1 }}>
                            <div>
                              <span>
                                <strong>{advancedSearchCriteria.length}</strong>&nbsp;advanced search filters applied -
                              </span>
                              <Button
                                className={"ms-1"}
                                color={"link"}
                                onClick={onAdvancedSearchToggle}
                                aria-label={intl.formatMessage({ id: "change.advanced.search.options" })}
                              >
                                Change
                              </Button>
                              &nbsp;|
                              <Button className={"ms-1"} color={"link"} onClick={() => onAdvancedSearch([])}>
                                Clear
                              </Button>
                            </div>
                            <div>
                              <Button className={"ms-1"} color={"link"} onClick={() => onSaveSearchToggle()}>
                                Save this search
                              </Button>
                            </div>
                          </div>
                        )}
                        {advancedSearchCriteria.length <= 0 && (
                          <Form
                            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                              e.preventDefault();
                              onSearchOnNewTerm();
                            }}
                          >
                            <InventorySearchAutoSuggest
                              searchTerm={searchTerm}
                              onSearchTermChange={onSearchTermChange}
                              onClear={onClearSearchTerm}
                              api={api}
                            />
                            <ButtonGroup>
                              <Button type="submit" color="primary" id="inventory-search-submit">
                                <FormattedMessage id="search" />
                              </Button>
                              <Button
                                color="primary"
                                onClick={onAdvancedSearchToggle}
                                aria-label={intl.formatMessage({ id: "open.advanced.search.options.dialog" })}
                              >
                                <FontAwesomeIcon icon={faChevronDown} />
                              </Button>
                            </ButtonGroup>
                          </Form>
                        )}
                        <Button
                          color="link"
                          onClick={() => setIsShowingFilter(!isShowingFilter)}
                          title={intl.formatMessage({ id: "filter" })}
                          className="ms-1 d-block d-xl-none"
                        >
                          <FontAwesomeIcon icon={faFilter} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="inventory-search-actions">
                  <AuthorizedComponent requiredPermissions={[RolePermissions.InventoryAddCreatedItem]}>
                    <Button
                      color="link"
                      tag={Link as any}
                      {...{ to: ApplicationPaths.Inventory.Add, state: { prevLocation: useLocation() } }}
                      data-tour="add-inventory"
                    >
                      <FontAwesomeIcon icon={faPlusCircle} className="button-icon" />
                      <FormattedMessage id="add.to.inventory" />
                    </Button>
                  </AuthorizedComponent>
                  <AuthorizedComponent requiredPermissions={[RolePermissions.LabManagementAllowed]}>
                    <Button
                      color="link"
                      onClick={onImportModalToggled}
                      className="d-none d-sm-block"
                      data-tour="import-inventory"
                      id={"import-inventory-prompt-button"}
                    >
                      <img
                        src="/images/Inventory_Import.svg"
                        className="link button-icon"
                        alt={intl.formatMessage({ id: "import.items" })}
                        aria-hidden={true}
                      />
                      <FormattedMessage id="import.items" />
                    </Button>
                  </AuthorizedComponent>
                  <Button color="link" onClick={onExportModalToggled} className="d-none d-sm-block" id={"export-inventory-prompt-button"}>
                    <img
                      src="/images/Inventory_Export.svg"
                      className="link button-icon"
                      alt={intl.formatMessage({ id: "export.items" })}
                      aria-hidden={true}
                    />
                    <FormattedMessage id="export.items" />
                  </Button>
                </Col>
              </Row>
              <div>
                <InventorySearchResults
                  isLoading={isLoading}
                  searchResults={searchResults}
                  onResultsOptionsChanged={onResultsOptionsChanged}
                  storageLocations={storageLocations}
                  currency={currency}
                  columnOrder={columnOrder}
                  itemLink={ApplicationPaths.Inventory.Item}
                  onUseNow={onUseNowOpened}
                  isUsed={isUsed}
                  onInventoryStorageChanged={onInventoryStorageChanged}
                  api={api}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <InventoryImportModal
        isOpen={isShowingImport}
        onCancel={onImportModalToggled}
        inventoryTypeViews={inventoryTypeViews}
        onItemsImported={onImportStarted}
        storageLocations={storageLocations}
      />

      <InventoryExportModal
        isOpen={isShowingExport}
        onCancel={onExportModalToggled}
        inventoryTypeViews={inventoryTypeViews}
        userViews={userViews}
        searchCriteria={searchCriteria}
        advancedSearchCriteria={advancedSearchCriteria}
        storage={storageLocations}
        freezerBoxCellFormatter={getFormattedFreezerBoxCells}
        onExportComplete={onExportModalToggled}
        api={api}
      />

      <UseNowModal
        isOpen={useNowProps.isOpen}
        onToggle={useNowProps.onToggle}
        defaultUsageAmount={useNowProps.defaultUsageAmount}
        defaultUsageUnit={useNowProps.defaultUsageUnit}
        displayFormat={useNowProps.displayFormat}
        matrixCols={useNowProps.matrixCols}
        matrixRows={useNowProps.matrixRows}
        selectedCells={useNowProps.selectedCells}
        occupiedCells={useNowProps.occupiedCells}
        onUsageSaved={useNowProps.onUsageSaved}
        onSelectedCellsChanged={useNowProps.onSelectedCellsChanged}
      />
    </>
  );
}
