import { CustomAttribute, CustomAttributeType, InventoryType } from "@labarchives/inventory-shared/build/inventory";
import { useState } from "react";
import { InventoryTypeView } from "../../inventory/types/views";
import { InventoryTypesState } from "../types/state";
import { getInventoryTypeFromView, getInventoryTypeViews } from "../selectors";
import { UserState } from "../../user/types/state";
import { UserAlerts } from "../../user/types/userAlerts";
import { RouterNavigationHooks } from "../../utils/useRouterNavigation";

export interface ManageInventoryTypesProps {
  id?: number;
  isAdding: boolean;
}

export interface ManageInventoryTypesHooks {
  activeInventoryTypeId?: number;
  isAdding: boolean;
  isLoading: boolean;
  inventoryTypeViews: InventoryTypeView[];
  showCommonFieldsAlert: boolean;

  onCancelAddType(): void;

  onCancelUpdateType(): void;

  onShowType(inventoryTypeView: InventoryTypeView): void;

  onDuplicateType(inventoryTypeView: InventoryTypeView): Promise<boolean>;

  onShowAddType(): void;

  onAddType(name: string): void;

  onUpdateType(inventoryType: InventoryType): void;

  onDeleteType(inventoryTypeView: InventoryTypeView): Promise<boolean>;

  onAttributeAdded(inventoryTypeId: number, attribute: CustomAttribute): void;

  onToggleCommonFieldsAlert(): void;

  isNameValid(name: string, id: number | undefined): boolean;

  isColorDuplicated(color: string | undefined, type: InventoryTypeView): boolean;
}

export function useManageInventoryTypes(
  props: ManageInventoryTypesProps,
  inventoryTypesState: InventoryTypesState,
  userState: UserState,
  routerNavigation: RouterNavigationHooks,
): ManageInventoryTypesHooks {
  const [showCommonFieldsAlert, setShowCommonFieldsAlert] = useState(userState.shouldDisplayAlert(UserAlerts.INVENTORY_TYPES_COMMON_FIELDS));

  const isNameValid = (name: string, id: number | undefined): boolean => {
    if (name.trim() === "") {
      return false;
    }
    const invalidChars = ["*", "?", ":", "\\", "/", "[", "]"];
    if (invalidChars.some((c) => name.includes(c))) {
      return false;
    }

    const hasConflict = inventoryTypesState.allIds
      .filter((typeId) => typeId !== id)
      .some((typeId) => {
        return inventoryTypesState.byId[typeId].name.toLocaleLowerCase() === name.toLocaleLowerCase();
      });

    return !hasConflict;
  };

  const addInventoryType = (inventoryType: InventoryType): Promise<void> => {
    return inventoryTypesState.onTypeAdded(inventoryType).then((added) => {
      return routerNavigation.navigateTo(`/management/types/${added.id}`);
    });
  };

  const onAddType = (name: string): void => {
    if (isNameValid(name, undefined)) {
      addInventoryType({ id: 0, name, customAttributes: [], isDefault: false, isLocked: false, color: null, standardFieldConfiguration: [] });
    }
  };

  const onShowType = (inventoryTypeView: InventoryTypeView): void => {
    routerNavigation.navigateTo(`/management/types/${inventoryTypeView.id}`);
  };

  const onDuplicateType = (inventoryTypeView: InventoryTypeView): Promise<boolean> => {
    const customAttributes: CustomAttribute[] = [];
    inventoryTypeView.attributes.forEach((attr) => {
      let { type } = attr;
      const { label, required, possibleValues } = attr;
      if (type === CustomAttributeType.CASNumber) {
        type = CustomAttributeType.Textbox;
      }
      if (type === CustomAttributeType.ChemicalSafety) {
        type = CustomAttributeType.Checkbox;
      }
      customAttributes.push({
        id: 0,
        label,
        type,
        required,
        possibleValues: [...possibleValues],
        isLocked: false,
      });
    });

    return addInventoryType({
      id: 0,
      name: `${inventoryTypeView.name} (copy)`,
      customAttributes,
      isDefault: false,
      isLocked: false,
      color: null,
      standardFieldConfiguration: [],
    })
      .then(() => true)
      .catch(() => false);
  };

  const onShowAddType = (): void => {
    routerNavigation.navigateTo(`/management/types/add`);
  };

  const onCancelAddType = (): void => {
    routerNavigation.navigateTo(`/management/types`);
  };

  const onCancelUpdateType = (): void => {
    routerNavigation.navigateTo(`/management/types`);
  };

  const onUpdateType = (inventoryType: InventoryType): void => {
    if (isNameValid(inventoryType.name, inventoryType.id)) {
      inventoryTypesState.onTypeUpdated(inventoryType);
    }
  };

  const onDeleteType = async (inventoryTypeView: InventoryTypeView): Promise<boolean> => {
    return inventoryTypesState.onTypeDeleted(getInventoryTypeFromView(inventoryTypeView));
  };

  const onAttributeAdded = (inventoryTypeId: number, attribute: CustomAttribute): void => {
    inventoryTypesState.onAttributeAdded(inventoryTypeId, attribute);
  };

  const onToggleCommonFieldsAlert = (): void => {
    userState.onToggleAlert(UserAlerts.INVENTORY_TYPES_COMMON_FIELDS);
    setShowCommonFieldsAlert(userState.shouldDisplayAlert(UserAlerts.INVENTORY_TYPES_COMMON_FIELDS));
  };

  function normalizeColor(color: string | undefined): string | undefined {
    if (!color) {
      return undefined;
    }

    let workingColor = color.trim().toUpperCase();

    if (!workingColor.startsWith("#")) {
      workingColor = `#${workingColor}`;
    }

    return workingColor;
  }

  const isColorDuplicated = (color: string | undefined, type: InventoryTypeView): boolean => {
    const normalizedColor = normalizeColor(color);

    if (!normalizedColor) {
      return false;
    }

    const hasConflict = inventoryTypesState.allIds
      .filter((typeId) => typeId !== type.id)
      .some((typeId) => {
        return inventoryTypesState.byId[typeId].color === normalizedColor;
      });

    return hasConflict;
  };

  return {
    activeInventoryTypeId: props.id,
    isAdding: props.isAdding,
    isLoading: inventoryTypesState.isLoading,
    inventoryTypeViews: getInventoryTypeViews(inventoryTypesState),
    onShowType,
    onDuplicateType,
    onShowAddType,
    onCancelAddType,
    onCancelUpdateType,
    onAddType,
    onDeleteType,
    onUpdateType,
    onAttributeAdded,
    showCommonFieldsAlert,
    onToggleCommonFieldsAlert,
    isNameValid,
    isColorDuplicated,
  };
}
