// eslint-disable-next-line max-classes-per-file
import Excel from "exceljs";
import moment from "moment";
import {
  CustomAttributeType,
  FreezerBoxDisplayFormat,
  InventoryCustomAttributeValue,
  InventorySearchResultSetItem,
  StorageLocation,
  InventoryImportItem,
  InventoryBulkUpdateItem,
  InventoryStandardField,
} from "@labarchives/inventory-shared/build/inventory";
import { getUnits } from "@labarchives/inventory-shared/build/util/units";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import * as EmailValidator from "email-validator";
import isUrl from "is-url";
import { InventoryTypeView } from "../types/views";
import { UserView } from "../../user/types/views";
import { ExcelFile } from "../../components/Excel/ExcelFile";
import { ExcelSheet } from "../../components/Excel/ExcelSheet";
import { StorageLocationView, NullStorageLocationView } from "../../storage/types/views";
import { getFreezerBoxLocationFromLabel } from "../../storage/selectors";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import { ParseResultError } from "./ParseResultError";
import { InventoryImportParseResult } from "./InventoryImportParseResult";
import { InventoryBulkUpdateParseResult } from "./InventoryBulkUpdateParseResult";
import { InventoryImportTemplateColumns, InventoryUpdateTemplateColumns } from "./InventoryImportTemplateColumns";
import { InventoryExcelParseResult } from "./InventoryExcelParseResult";

interface RowError {
  id: string;
  columnName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: { [key: string]: any };
}

class SheetErrorAggregator {
  private readonly sheetName: string;

  private readonly columnErrors: { [columnName: string]: RowError[] };

  public constructor(sheetName: string) {
    this.sheetName = sheetName;
    this.columnErrors = {};
  }

  public addErrors(errors: RowError[]): void {
    errors.forEach((e) => {
      if (!this.columnErrors[e.columnName]) {
        this.columnErrors[e.columnName] = [];
      }

      if (!this.columnErrors[e.columnName].some((existingError) => existingError.id === e.id)) {
        this.columnErrors[e.columnName].push(e);
      }
    });
  }

  public getErrors(): ParseResultError[] {
    let allErrors: ParseResultError[] = [];
    Object.keys(this.columnErrors).forEach((column) => {
      allErrors = [
        ...allErrors,
        ...this.columnErrors[column].map((e) => {
          return { id: e.id, values: { ...e.values, columnName: e.columnName, sheetName: this.sheetName } };
        }),
      ];
    });

    return allErrors;
  }
}

interface InventoryExportMap {
  columnName: string;
  propertyName: string;
  fieldName: InventoryStandardField | null;
  updateOnly?: boolean;
}

const inventoryExcelMap: InventoryExportMap[] = [
  { columnName: InventoryImportTemplateColumns.Name, propertyName: "name", fieldName: InventoryStandardField.Name },
  { columnName: InventoryImportTemplateColumns.Vendor, propertyName: "vendorName", fieldName: InventoryStandardField.Vendor },
  { columnName: InventoryImportTemplateColumns.CatalogNumber, propertyName: "catalogNumber", fieldName: InventoryStandardField.CatalogNumber },
  { columnName: InventoryImportTemplateColumns.DateReceived, propertyName: "dateReceived", fieldName: InventoryStandardField.DateReceived },
  { columnName: InventoryImportTemplateColumns.Owner, propertyName: "", fieldName: null },
  { columnName: InventoryImportTemplateColumns.Quantity, propertyName: "quantityAvailable", fieldName: InventoryStandardField.Quantity },
  { columnName: InventoryImportTemplateColumns.Unit, propertyName: "unit", fieldName: null },
  { columnName: InventoryImportTemplateColumns.Price, propertyName: "price", fieldName: InventoryStandardField.Price },
  { columnName: InventoryImportTemplateColumns.Description, propertyName: "description", fieldName: InventoryStandardField.Description },
  { columnName: InventoryImportTemplateColumns.ExpirationDate, propertyName: "expiration", fieldName: InventoryStandardField.Expiration },
  { columnName: InventoryImportTemplateColumns.GrantNumber, propertyName: "grantNumber", fieldName: InventoryStandardField.GrantNumber },
  { columnName: InventoryImportTemplateColumns.PoNumber, propertyName: "poNumber", fieldName: InventoryStandardField.PONumber },
  { columnName: InventoryImportTemplateColumns.LotNumber, propertyName: "lotNumber", fieldName: InventoryStandardField.LotNumber },
  { columnName: InventoryImportTemplateColumns.Notes, propertyName: "notes", fieldName: InventoryStandardField.Notes },
  { columnName: InventoryImportTemplateColumns.SafetySheetUrl, propertyName: "safetySheetUrl", fieldName: InventoryStandardField.SafetySheet },
  { columnName: InventoryImportTemplateColumns.StorageTop, propertyName: "", fieldName: InventoryStandardField.Location },
  { columnName: InventoryImportTemplateColumns.StorageBottom, propertyName: "", fieldName: null },
  { columnName: InventoryImportTemplateColumns.StorageFreezerBoxCells, propertyName: "", fieldName: null },
  { columnName: InventoryImportTemplateColumns.ReorderNotificationQuantity, propertyName: "reorderNotificationQuantity", fieldName: null },
  { columnName: InventoryImportTemplateColumns.ReorderNotificationUnit, propertyName: "reorderNotificationUnit", fieldName: null },
  { columnName: InventoryUpdateTemplateColumns.ItemURL, propertyName: "", updateOnly: true, fieldName: null },
  { columnName: InventoryUpdateTemplateColumns.ItemId, propertyName: "itemId", updateOnly: true, fieldName: null },
];

export type FreezerBoxCellFormatter = (cells: string[], format: FreezerBoxDisplayFormat | null, rows: number | null, cols: number | null) => string;

enum InventoryExcelParseMode {
  Import,
  BulkUpdate,
}

export class InventoryExcel extends ExcelFile {
  private static INSTRUCTIONS_SHEET_NAME = "Instructions";

  private types: InventoryTypeView[] = [];

  private storage: StorageLocationView;

  public constructor(types: InventoryTypeView[], storage: StorageLocationView) {
    super();
    this.types = types;
    this.storage = storage;
  }

  public static Create(types: InventoryTypeView[], storage: StorageLocationView): InventoryExcel {
    return new InventoryExcel(types, storage);
  }

  public static GetTemplate(types: InventoryTypeView[]): InventoryExcel {
    const excel = new InventoryExcel(types, new NullStorageLocationView());

    const excelSheet = new ExcelSheet(InventoryExcel.INSTRUCTIONS_SHEET_NAME, "FFFFFFE0");
    excelSheet.AddMergedCells(
      "A1",
      "M25",
      "Please read before using the template:\r\n" +
        "\r\n" +
        "You cannot make any changes to the template (no deletions, additions, or changes to column names). It is ok to leave an unused field blank (except for required fields).\r\n" +
        "\r\n" +
        "Required fields = Item Name and any required standard or custom fields set for an Inventory Type.\r\n" +
        "\r\n" +
        "Different Inventory Types are on separate tabs of the template. Do not delete tabs you are not using; just leave them blank.\r\n" +
        "\r\n" +
        "If a quantity is not listed, a value of 1 is assumed.\r\n" +
        "\r\n" +
        "Vendors will be automatically created if they don’t exist in your list of Vendors.",
    );
    excel.sheets.push(excelSheet);

    types.forEach((t) => {
      const visibleStandardColumns = this.getVisibleStandardColumnsForType(t, false);
      const requiredStandardColumns = this.getRequiredStandardColumnsForType(t, false);
      const sheet = new ExcelSheet(t.name);
      const attributeNames = t.attributes.map((a) => a.label);
      sheet.AddColumns([...visibleStandardColumns, ...attributeNames]);
      sheet.SetRequiredColumns([...requiredStandardColumns, ...t.attributes.filter((a) => a.required).map((a) => a.label)]);
      excel.sheets.push(sheet);
    });

    [1, 2, 3, 4, 5].forEach((n) => {
      const sheet = new ExcelSheet(`New Type ${n}`, "FFFAFAFA");
      sheet.AddColumns(inventoryExcelMap.filter((c) => !c.updateOnly).map((c) => c.columnName));
      sheet.SetRequiredColumns([InventoryImportTemplateColumns.Name.toString()]);
      excel.sheets.push(sheet);
    });

    return excel;
  }

  private static getRequiredStandardColumnsForType(t: InventoryTypeView, updateOnly: boolean): string[] {
    const importOrExportCols = updateOnly ? inventoryExcelMap : inventoryExcelMap.filter((c) => !c.updateOnly);

    function isRequired(fieldName: InventoryStandardField | null): boolean {
      if (!fieldName) {
        return false;
      }

      const standardFieldConfig = t.standardFieldConfiguration.find((sfc) => sfc.field === fieldName);
      if (!standardFieldConfig) {
        return false;
      }

      return standardFieldConfig.required;
    }

    return importOrExportCols
      .filter((sc) => sc.columnName === InventoryImportTemplateColumns.Name || isRequired(sc.fieldName))
      .map((sc) => sc.columnName);
  }

  private static getVisibleStandardColumnsForType(t: InventoryTypeView, updateOnly: boolean): string[] {
    const importOrExportCols = updateOnly ? inventoryExcelMap : inventoryExcelMap.filter((c) => !c.updateOnly);

    function isVisible(fieldName: InventoryStandardField): boolean {
      const standardFieldConfig = t.standardFieldConfiguration.find((sfc) => sfc.field === fieldName);
      if (!standardFieldConfig) {
        return true;
      }

      return standardFieldConfig.displayed;
    }

    return importOrExportCols.filter((sc) => sc.fieldName === null || isVisible(sc.fieldName)).map((sc) => sc.columnName);
  }

  public async Parse(file: File): Promise<InventoryImportParseResult> {
    return this.ParseExcel<InventoryImportItem>(file, InventoryExcelParseMode.Import);
  }

  public async ParseBulkUpdate(file: File): Promise<InventoryBulkUpdateParseResult> {
    return this.ParseExcel<InventoryBulkUpdateItem>(file, InventoryExcelParseMode.BulkUpdate);
  }

  public static CreateExport(
    items: InventorySearchResultSetItem[],
    types: InventoryTypeView[],
    users: UserView[],
    storage: StorageLocationView,
    cellFormatter: FreezerBoxCellFormatter,
    includeInstructions = false,
  ): InventoryExcel {
    const excel = new InventoryExcel(types, new NullStorageLocationView());

    if (includeInstructions) {
      const excelSheet = new ExcelSheet(InventoryExcel.INSTRUCTIONS_SHEET_NAME, "FFFFFFE0");
      excelSheet.AddMergedCells(
        "A1",
        "M25",
        "Using bulk update, you cannot:\r\n" +
          "Create a new item from import\r\n\r\n" +
          "Create new fields\n\r\n\r\n" +
          "Create new inventory types\n\r\n\r\n" +
          "Create storage locations\n\r\n\r\n" +
          "Edit Item URLs\r\n\r\n" +
          "Edit Item Ids\r\n\r\n" +
          "Add attachments\r\n\r\n" +
          "Change inventory types for an item.",
      );
      excel.sheets.push(excelSheet);
    }

    types.forEach((type) => {
      excel.sheets.push(InventoryExcel.BuildExportSheet(type, items, users, storage, cellFormatter));
    });

    return excel;
  }

  private static BuildExportSheet(
    type: InventoryTypeView,
    items: InventorySearchResultSetItem[],
    users: UserView[],
    storage: StorageLocationView,
    cellFormatter: FreezerBoxCellFormatter,
  ): ExcelSheet {
    function formatDate(date: Date): string {
      return moment(date).format(clock.STANDARD_MOMENT_DATE_FORMAT);
    }

    function addLocationInformation(item: InventorySearchResultSetItem, row: string[]): void {
      const addBlanks = (): number => row.push("", "", "");

      if (item.locationId) {
        const location = storage.getLocation(item.locationId);
        if (!location) {
          addBlanks();
          return;
        }
        const cells = cellFormatter(item.storageCells, item.storageCellFormat, item.storageCellRows, item.storageCellCols);

        if (!location.parentId) {
          row.push(location.name, "", cells);
          return;
        }

        const topLocation = storage.getTopParent(location.parentId);
        const topName = topLocation.name;
        const bottomName = location.name;
        row.push(topName, bottomName, cells);
      } else {
        addBlanks();
      }
    }

    function addOwnerInformation(item: InventorySearchResultSetItem, row: string[]): void {
      const user = users.find((u) => u.id === item.ownerId);
      if (user) {
        row.push(user.email);
      } else {
        row.push("");
      }
    }

    function addItemUrl(item: InventorySearchResultSetItem, row: string[]): void {
      row.push(ApplicationPaths.Inventory.GetFullUrl(item.itemId || item.id));
    }

    function addCommonDataToRow(item: InventorySearchResultSetItem, row: string[], visibleColumns: string[]): void {
      function isColumnDisplayed(column: InventoryExportMap): boolean {
        return visibleColumns.includes(column.columnName);
      }

      inventoryExcelMap.forEach((column) => {
        switch (column.columnName) {
          case "Storage Location - Top": {
            addLocationInformation(item, row);

            break;
          }
          case "Owner (email address)": {
            addOwnerInformation(item, row);

            break;
          }
          case "Item URL": {
            addItemUrl(item, row);

            break;
          }
          case "Storage Location - Bottom":
          case "Storage Location - Freezer Box Cells": {
            // we add all storage information when we hit the Storage Location - Top column
            break;
          }
          default: {
            if (!isColumnDisplayed(column)) {
              break;
            }
            if (column.propertyName in item) {
              // @ts-ignore
              const value = item[column.propertyName];
              if (value) {
                if (value instanceof Date) {
                  row.push(formatDate(value));
                } else {
                  row.push(value.toString());
                }
              } else {
                row.push("");
              }
            } else {
              row.push("");
            }
          }
        }
      });
    }

    function addInventoryTypeDataToRow(item: InventorySearchResultSetItem, row: string[]): void {
      type.attributes.forEach((typeAttribute) => {
        const attributeValue = item.customAttributes.find((a) => a.customAttributeId === typeAttribute.id);
        if (attributeValue) {
          try {
            const { values } = attributeValue;
            if (typeAttribute.type === CustomAttributeType.Date && values[0] && values[0] !== "") {
              const value = new Date(values[0]);
              if (Number.isNaN(value)) {
                row.push("");
              } else {
                row.push(formatDate(value));
              }
            } else {
              row.push(values.join("\n"));
            }
          } catch {
            row.push("");
          }
        } else {
          row.push("");
        }
      });
    }

    const sheet = new ExcelSheet(type.name);

    const visibleCols = this.getVisibleStandardColumnsForType(type, true);
    const requiredCols = this.getRequiredStandardColumnsForType(type, true);
    sheet.AddColumns([...visibleCols, ...type.attributes.map((a) => a.label)]);
    sheet.SetRequiredColumns(requiredCols);

    items.forEach((item) => {
      if (item.typeId === type.id) {
        const row: string[] = [];
        addCommonDataToRow(item, row, visibleCols);
        addInventoryTypeDataToRow(item, row);
        sheet.AppendRow(row);
      }
    });

    return sheet;
  }

  private GetWorkbookErrorIds(workbook: Excel.Workbook, mode: InventoryExcelParseMode): ParseResultError[] {
    const templateColumnNames: string[] = [];

    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const enumItem in InventoryImportTemplateColumns) {
      // @ts-ignore
      templateColumnNames.push(InventoryImportTemplateColumns[enumItem]);
    }

    if (mode === InventoryExcelParseMode.BulkUpdate) {
      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for (const enumItem in InventoryUpdateTemplateColumns) {
        // @ts-ignore
        templateColumnNames.push(InventoryUpdateTemplateColumns[enumItem]);
      }
    }

    const checkRequiredColumns = (sheet: Excel.Worksheet, columnErrors: ParseResultError[]): void => {
      const missingColumns: string[] = [];
      const headerRow = sheet.getRow(1);

      const currentType = this.types.find((t) => t.name.toLocaleLowerCase() === sheet.name.toLocaleLowerCase());
      if (currentType) {
        templateColumnNames.forEach((name) => {
          const mappedColumn = inventoryExcelMap.find((col) => col.columnName === name)!;
          const standardFieldConfiguration = currentType.standardFieldConfiguration.find((f) => f.field === mappedColumn.fieldName);
          const canBeSkipped = standardFieldConfiguration?.displayed === false;

          const index = this.GetColumnIndexAsNumber(headerRow, name);
          if (index < 0 && !canBeSkipped) {
            missingColumns.push(name);
          }
        });

        currentType.attributes.forEach((att) => {
          const index = this.GetColumnIndexAsNumber(headerRow, att.label);
          if (index < 0) {
            missingColumns.push(att.label);
          }
        });
      }

      if (missingColumns.length > 0) {
        columnErrors.push({
          id: "inventory.upload.error.missing.required.columns",
          values: { sheetName: sheet.name, columnNames: missingColumns.join(", ") },
        });
      }
    };

    const checkAdditionalColumns = (sheet: Excel.Worksheet, columnErrors: ParseResultError[]): void => {
      const headerRow = sheet.getRow(1);
      const values: string[] = [];
      headerRow.eachCell((c) => {
        if (c.value) {
          values.push(c.value.toString());
        }
      });

      const additionalColumns: string[] = [];

      const isCommonColumn = (columnName: string): boolean => {
        return templateColumnNames.some((name) => name.toLowerCase() === columnName);
      };

      const isCustomColumn = (columnName: string): boolean => {
        const currentType = this.types.find((t) => t.name.toLocaleLowerCase() === sheet.name.toLocaleLowerCase());
        if (currentType) {
          return currentType.attributes.some((att) => att.label.trim().toLowerCase() === columnName);
        }

        return true;
      };

      values.forEach((v) => {
        const columnName = v.toString().trim().toLowerCase();

        if (!isCommonColumn(columnName) && !isCustomColumn(columnName)) {
          additionalColumns.push(v);
        }
      });

      if (additionalColumns.length > 0) {
        columnErrors.push({
          id: "inventory.upload.error.additional.columns",
          values: { sheetName: sheet.name, columnNames: additionalColumns.join(", ") },
        });
      }
    };

    const checkDuplicateColumns = (sheet: Excel.Worksheet, columnErrors: ParseResultError[]): void => {
      const headerRow = sheet.getRow(1);
      const duplicates: string[] = [];
      const values: { column: string; lowerCaseColumn: string }[] = [];
      headerRow.eachCell((c) => {
        if (c.value) {
          values.push({ column: c.value.toString(), lowerCaseColumn: c.value.toString().toLocaleLowerCase() });
        }
      });

      values.sort((v1, v2) => {
        return v1.lowerCaseColumn.localeCompare(v2.lowerCaseColumn);
      });

      for (let i = 0; i < values.length - 1; i++) {
        if (values[i + 1].lowerCaseColumn === values[i].lowerCaseColumn) {
          duplicates.push(values[i].column);
        }
      }

      if (duplicates.length > 0) {
        columnErrors.push({
          id: "inventory.upload.error.duplicate.columns",
          values: { sheetName: sheet.name, columnNames: duplicates.join(", ") },
        });
      }
    };

    const errors: ParseResultError[] = [];
    this.types.forEach((type) => {
      const sheet = workbook.getWorksheet(type.name);
      if (sheet) {
        checkRequiredColumns(sheet, errors);
        checkAdditionalColumns(sheet, errors);
        checkDuplicateColumns(sheet, errors);
      } else {
        errors.push({ id: "inventory.upload.error.missing.sheet", values: { sheetName: type.name } });
      }
    });

    return errors;
  }

  private GetColumnIndexAsNumber(row: Excel.Row, index: number | string): number {
    if (typeof index === "string") {
      const headers = row.worksheet.getRow(1);
      if (headers && headers.values) {
        // @ts-ignore
        return headers.values.indexOf(index);
      }

      return -1;
    }

    return index;
  }

  private GetRowErrors(
    row: Excel.Row,
    sheet: Excel.Worksheet,
    rowNumber: number,
    type: InventoryTypeView | undefined,
    mode: InventoryExcelParseMode,
  ): RowError[] {
    const errors: RowError[] = [];

    const validateDates = (): void => {
      const checkFormat = (column: string): boolean => {
        const value = this.GetCellValue(row, column);
        if (value === "") {
          return true;
        }

        const date = this.GetDateCellValue(row, column);
        return date !== null;
      };

      const typeColumns = type ? type.attributes.filter((a) => a.type === CustomAttributeType.Date).map((attribute) => attribute.label) : [];
      const columnsToCheck = [
        InventoryImportTemplateColumns.DateReceived.toString(),
        InventoryImportTemplateColumns.ExpirationDate.toString(),
        ...typeColumns,
      ];

      columnsToCheck.forEach((column) => {
        if (!checkFormat(column)) {
          errors.push({
            id: "inventory.upload.error.invalid.date",
            columnName: column,
          });
        }
      });
    };

    const validateRequired = (): void => {
      const typeColumns = type ? type.attributes.filter((a) => a.required).map((attribute) => attribute.label) : [];
      const requiredColumns = type
        ? InventoryExcel.getRequiredStandardColumnsForType(type, mode === InventoryExcelParseMode.BulkUpdate)
        : [InventoryImportTemplateColumns.Name.toString()];
      const columnsToCheck: string[] = [...requiredColumns, ...typeColumns];

      if (mode === InventoryExcelParseMode.BulkUpdate) {
        columnsToCheck.push(InventoryUpdateTemplateColumns.ItemURL);
      }

      columnsToCheck.forEach((column) => {
        if (this.GetCellValue(row, column) === "") {
          errors.push({
            id: "inventory.upload.error.required.field",
            columnName: column,
          });
        }
      });
    };

    const validateUnits = (col: InventoryImportTemplateColumns, errorId: string): void => {
      const unit = this.GetCellValue(row, col);
      const validUnits = getUnits();
      if (unit !== "" && !validUnits.some((u) => u.toLocaleLowerCase() === unit.toLocaleLowerCase())) {
        errors.push({
          id: errorId,
          columnName: col,
          values: { validUnits: validUnits.join(", ") },
        });
      }
    };

    const validateQuantity = (col: InventoryImportTemplateColumns, errorId: string): void => {
      const amount = this.GetCellValue(row, col);
      const numericAmount = Number.parseFloat(amount);
      if (amount !== "" && Number.isNaN(numericAmount)) {
        errors.push({
          id: errorId,
          columnName: col,
        });
      }
    };

    const validatePrice = (): void => {
      const price = this.GetCellValue(row, InventoryImportTemplateColumns.Price);
      const numericAmount = Number.parseFloat(price);
      if (price !== "" && Number.isNaN(numericAmount)) {
        errors.push({
          id: "inventory.upload.error.invalid.price",
          columnName: InventoryImportTemplateColumns.Price,
        });
      }
    };

    const validateEmail = (): void => {
      const email = this.GetCellValue(row, InventoryImportTemplateColumns.Owner);
      if (email !== "" && !EmailValidator.validate(email)) {
        errors.push({
          id: "inventory.upload.error.invalid.owner",
          columnName: InventoryImportTemplateColumns.Owner,
        });
      }
    };

    const validateSafetySheet = (): void => {
      const url = this.GetCellValue(row, InventoryImportTemplateColumns.SafetySheetUrl);
      if (url !== "" && !isUrl(url)) {
        errors.push({
          id: "inventory.upload.error.invalid.safety.sheet",
          columnName: InventoryImportTemplateColumns.SafetySheetUrl,
        });
      }
    };

    const validateCustomNumeric = (): void => {
      const columnsToCheck: string[] = type
        ? type.attributes.filter((a) => a.type === CustomAttributeType.Number).map((attribute) => attribute.label)
        : [];

      columnsToCheck.forEach((column) => {
        const value = this.GetCellValue(row, column);
        const numericAmount = Number.parseFloat(value);
        if (value !== "" && Number.isNaN(numericAmount)) {
          errors.push({
            id: "inventory.upload.error.invalid.number",
            columnName: column,
          });
        }
      });
    };

    const validateCustomCheckbox = (): void => {
      const checkboxColumns = type
        ? type.attributes.filter((a) => a.type === CustomAttributeType.Checkbox || a.type === CustomAttributeType.ChemicalSafety)
        : [];

      checkboxColumns.forEach((column) => {
        const values = this.GetCellValue(row, column.label)
          .split(/[\n\r,]+/)
          .map((v) => v.trim())
          .filter((v) => v !== "");

        const validValues = column.possibleValues;

        if (values.length > 0 && !values.every((v) => validValues.includes(v))) {
          errors.push({
            id: "inventory.upload.error.invalid.value",
            columnName: column.label,
            values: { validValues: validValues.join(", ") },
          });
        }
      });
    };

    const validateCustomDropdownOrRadio = (): void => {
      const dropdownColumns = type
        ? type.attributes.filter((a) => a.type === CustomAttributeType.Dropdown || a.type === CustomAttributeType.Radio)
        : [];

      dropdownColumns.forEach((column) => {
        const value = this.GetCellValue(row, column.label);

        const validValues = column.possibleValues;

        if (value !== "" && !validValues.includes(value)) {
          errors.push({
            id: "inventory.upload.error.invalid.value",
            columnName: column.label,
            values: { validValues: validValues.join(", ") },
          });
        }
      });
    };

    const validateStorageLocation = (): void => {
      const isInvalidFreezerBox = (cellsToCheck: string, location: StorageLocation): boolean => {
        return cellsToCheck !== "" && location.numberOfColumns === null;
      };

      const isFreezerBoxOutOfRange = (splitCells: string[], location: StorageLocation): boolean => {
        if (splitCells.length === 0 || location.numberOfColumns === null || location.numberOfRows === null) {
          return false;
        }
        const cols = location.numberOfColumns;
        const rows = location.numberOfRows;
        const { freezerBoxDisplayFormat } = location;

        const cellLocations = splitCells.map((c) => getFreezerBoxLocationFromLabel(c.trim().toUpperCase(), rows, cols, freezerBoxDisplayFormat));

        return cellLocations.some((l) => l.col > cols || l.row > rows);
      };

      const areCellsIncorrectFormat = (splitCells: string[], location: StorageLocation): boolean => {
        let regex = new RegExp(/^[A-Za-z]+\d+$/);
        if (location.freezerBoxDisplayFormat === FreezerBoxDisplayFormat.NumberLetter) {
          regex = new RegExp(/^\d+[A-Za-z]+$/);
        }
        if (location.freezerBoxDisplayFormat === FreezerBoxDisplayFormat.NumberOnly) {
          regex = new RegExp(/^\d+$/);
        }

        return splitCells.some((c) => !regex.test(c));
      };

      const validateFreezerBox = (cells: string, location: StorageLocation): void => {
        if (isInvalidFreezerBox(cells, location)) {
          errors.push({ id: "inventory.upload.error.invalid.freezer.box", columnName: InventoryImportTemplateColumns.StorageFreezerBoxCells });
          return;
        }

        const splitCells = cells.split(/[ ,]+/).filter((c) => c.trim() !== "");

        if (areCellsIncorrectFormat(splitCells, location)) {
          errors.push({ id: "inventory.upload.error.freezer.box.invalid.format", columnName: InventoryImportTemplateColumns.StorageFreezerBoxCells });
          return;
        }

        if (isFreezerBoxOutOfRange(splitCells, location)) {
          errors.push({ id: "inventory.upload.error.freezer.box.out.of.range", columnName: InventoryImportTemplateColumns.StorageFreezerBoxCells });
        }
      };

      const top = this.GetCellValue(row, InventoryImportTemplateColumns.StorageTop);
      const bottom = this.GetCellValue(row, InventoryImportTemplateColumns.StorageBottom);
      const cells = this.GetCellValue(row, InventoryImportTemplateColumns.StorageFreezerBoxCells);

      if (top === "" && bottom === "" && cells === "") {
        return;
      }

      if (top === "" && (bottom !== "" || cells !== "")) {
        errors.push({ id: "inventory.upload.error.missing.top.location", columnName: InventoryImportTemplateColumns.StorageTop });
        return;
      }

      const topLevelLocation = this.storage.getTopLevelLocation(top);
      if (!topLevelLocation) {
        errors.push({ id: "inventory.upload.error.invalid.top.location", columnName: InventoryImportTemplateColumns.StorageTop });
        return;
      }

      const allLocations = this.storage.getAllLocationsInTree(topLevelLocation.id);
      if (allLocations.length !== 1 && bottom === "") {
        errors.push({ id: "inventory.upload.error.missing.bottom.location", columnName: InventoryImportTemplateColumns.StorageBottom });
        return;
      }

      if (allLocations.length === 1) {
        // single level location
        validateFreezerBox(cells, topLevelLocation);
        return;
      }

      const bottomLocation = allLocations.find((l) => l.name.trim().toLowerCase() === bottom.trim().toLowerCase());

      if (!bottomLocation) {
        errors.push({ id: "inventory.upload.error.invalid.bottom.location", columnName: InventoryImportTemplateColumns.StorageBottom });
        return;
      }

      if (allLocations.some((l) => l.parentId === bottomLocation.id)) {
        errors.push({ id: "inventory.upload.error.invalid.bottom.location", columnName: InventoryImportTemplateColumns.StorageBottom });
        return;
      }

      validateFreezerBox(cells, bottomLocation);
    };

    validateRequired();
    validateDates();
    validateUnits(InventoryImportTemplateColumns.Unit, "inventory.upload.error.invalid.unit");
    validateUnits(InventoryImportTemplateColumns.ReorderNotificationUnit, "inventory.upload.error.invalid.reorder.unit");
    validateQuantity(InventoryImportTemplateColumns.Quantity, "inventory.upload.error.invalid.amount");
    validateQuantity(InventoryImportTemplateColumns.ReorderNotificationQuantity, "inventory.upload.error.invalid.reorder.amount");
    validatePrice();
    validateEmail();
    validateSafetySheet();
    validateCustomNumeric();
    validateCustomCheckbox();
    validateCustomDropdownOrRadio();
    validateStorageLocation();

    return errors;
  }

  private GetCellValue(row: Excel.Row, index: number | string): string {
    const cellIndex = this.GetColumnIndexAsNumber(row, index);
    if (cellIndex < 1) {
      return "";
    }

    const cell = row.getCell(cellIndex);
    if (cell && cell.value) {
      // @ts-ignore
      return cell.value.hasOwnProperty("text") ? cell.value.text.toString().trim() : cell.value.toString().trim();
    }

    return "";
  }

  private GetDateCellValue(row: Excel.Row, index: number | string): Date | null {
    const cellIndex = this.GetColumnIndexAsNumber(row, index);
    if (cellIndex < 1) {
      return null;
    }

    const cell = row.getCell(cellIndex);
    if (cell && cell.value && cell.value instanceof Date) {
      // exceljs assumes we're providing dates as utc, so this just makes it local timezone
      return clock.midDay(new Date(cell.value.getUTCFullYear(), cell.value.getUTCMonth(), cell.value.getUTCDate()));
    }

    const dateStringRegex = /^\d{4}-\d{2}-\d{2}$/;
    const value = this.GetCellValue(row, index);
    if (value !== "" && dateStringRegex.test(value) && !Number.isNaN(new Date(value).getTime())) {
      return clock.midDay(moment(value, clock.STANDARD_MOMENT_DATE_FORMAT).toDate());
    }

    return null;
  }

  private GetNumberCellValue(row: Excel.Row, index: number | string): number | null {
    const parsed = Number.parseFloat(this.GetCellValue(row, index));

    if (Number.isNaN(parsed)) {
      return null;
    }

    return parsed;
  }

  private GetAttributes(row: Excel.Row, type?: InventoryTypeView): InventoryCustomAttributeValue[] {
    const attributeValues: InventoryCustomAttributeValue[] = [];
    if (!type) {
      return attributeValues;
    }

    type.attributes.forEach((attribute) => {
      let value: string = this.GetCellValue(row, attribute.label);
      if (attribute.type === CustomAttributeType.Date) {
        value = "";
        const date = this.GetDateCellValue(row, attribute.label);
        if (date) {
          value = moment(date).format(clock.STANDARD_MOMENT_DATE_FORMAT);
        }
      }
      if (attribute.type === CustomAttributeType.Checkbox) {
        attributeValues.push({
          customAttributeId: attribute.id,
          values: value
            .split(/[\n\r,]+/)
            .map((v) => v.trim())
            .filter((v) => v !== ""),
        });
      } else {
        attributeValues.push({ customAttributeId: attribute.id, values: [value] });
      }
    });

    return attributeValues;
  }

  private GetLocation(row: Excel.Row): StorageLocation | undefined {
    const top = this.GetCellValue(row, InventoryImportTemplateColumns.StorageTop);
    const bottom = this.GetCellValue(row, InventoryImportTemplateColumns.StorageBottom);

    const topLevelLocation = this.storage.getTopLevelLocation(top);

    if (!topLevelLocation) {
      return undefined;
    }

    const allLocations = this.storage.getAllLocationsInTree(topLevelLocation.id);
    if (allLocations.length === 1) {
      return topLevelLocation;
    }

    return allLocations.find((l) => l.name.trim().toLowerCase() === bottom.trim().toLowerCase());
  }

  private GetFreezerBoxCells(row: Excel.Row, location: StorageLocation | undefined): string[] {
    const cells = this.GetCellValue(row, InventoryImportTemplateColumns.StorageFreezerBoxCells);

    if (cells === "" || location === undefined || location.numberOfRows === null || location.numberOfColumns === null) {
      return [];
    }

    // @ts-ignore
    const uniqueCells = [...new Set(cells.split(/[ ,]+/).filter((c) => c.trim() !== ""))];

    return uniqueCells.map(
      (c) => getFreezerBoxLocationFromLabel(c, location.numberOfRows!, location.numberOfColumns!, location.freezerBoxDisplayFormat!).normalizedLabel,
    );
  }

  private GetFreezerBoxHasDuplicates(item: InventoryImportItem, items: InventoryImportItem[], sheetName: string): RowError[] {
    if (item.storageLocationId !== null && item.storageCells.length > 0) {
      const itemsInThisFreezerBox = items.filter((i) => i.storageLocationId === item.storageLocationId);
      const duplicated = itemsInThisFreezerBox.some((i) => i.storageCells.some((c) => item.storageCells.includes(c)));
      if (duplicated) {
        return [
          {
            columnName: InventoryImportTemplateColumns.StorageFreezerBoxCells,
            id: "inventory.upload.error.freezer.box.duplicated",
            values: { sheetName },
          },
        ];
      }
    }

    return [];
  }

  private GetItemFromRow(row: Excel.Row, typeName: string, type: InventoryTypeView | undefined): InventoryImportItem {
    const location = this.GetLocation(row);
    const cells = this.GetFreezerBoxCells(row, location);
    return {
      name: this.GetCellValue(row, InventoryImportTemplateColumns.Name),
      type: typeName,
      vendor: this.GetCellValue(row, InventoryImportTemplateColumns.Vendor),
      catalogNumber: this.GetCellValue(row, InventoryImportTemplateColumns.CatalogNumber),
      dateReceived: this.GetDateCellValue(row, InventoryImportTemplateColumns.DateReceived),
      owner: this.GetCellValue(row, InventoryImportTemplateColumns.Owner),
      amount: this.GetNumberCellValue(row, InventoryImportTemplateColumns.Quantity),
      description: this.GetCellValue(row, InventoryImportTemplateColumns.Description),
      expirationDate: this.GetDateCellValue(row, InventoryImportTemplateColumns.ExpirationDate),
      grantNumber: this.GetCellValue(row, InventoryImportTemplateColumns.GrantNumber),
      poNumber: this.GetCellValue(row, InventoryImportTemplateColumns.PoNumber),
      lotNumber: this.GetCellValue(row, InventoryImportTemplateColumns.LotNumber),
      price: this.GetNumberCellValue(row, InventoryImportTemplateColumns.Price),
      notes: this.GetCellValue(row, InventoryImportTemplateColumns.Notes),
      safetySheetUrl: this.GetCellValue(row, InventoryImportTemplateColumns.SafetySheetUrl),
      unit: this.GetCellValue(row, InventoryImportTemplateColumns.Unit),
      attributes: this.GetAttributes(row, type),
      storageLocationId: location ? location.id : null,
      storageCells: cells,
      reorderNotificationAmount: this.GetNumberCellValue(row, InventoryImportTemplateColumns.ReorderNotificationQuantity),
      reorderNotificationUnit: this.GetCellValue(row, InventoryImportTemplateColumns.ReorderNotificationUnit),
    };
  }

  private async ParseExcel<T extends InventoryBulkUpdateItem | InventoryImportItem>(
    file: File,
    mode: InventoryExcelParseMode,
  ): Promise<InventoryExcelParseResult<T>> {
    const HEADER_ROW_NUMBER = 1;
    try {
      const workbook = await new Promise<Excel.Workbook>((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(new Excel.Workbook().xlsx.load(reader.result as Excel.Buffer)));
        reader.readAsArrayBuffer(file);
      });

      const fileDate = workbook.created;

      const errors = this.GetWorkbookErrorIds(workbook, mode);

      if (errors.length > 0) {
        return { isValid: false, items: [], errors, fileDate };
      }

      const items: T[] = [];
      workbook.worksheets
        .filter(
          (sheet) =>
            !sheet.name.toLocaleLowerCase().startsWith("new type") &&
            sheet.name.toLocaleLowerCase() !== InventoryExcel.INSTRUCTIONS_SHEET_NAME.toLocaleLowerCase(),
        )
        .forEach((sheet) => {
          const aggregator = new SheetErrorAggregator(sheet.name);

          sheet.eachRow((row, rowNumber) => {
            if (rowNumber !== HEADER_ROW_NUMBER) {
              const type = this.types.find((t) => t.name.toLocaleLowerCase() === sheet.name.toLocaleLowerCase());
              const sheetErrors = this.GetRowErrors(row, sheet, rowNumber, type, mode);
              aggregator.addErrors(sheetErrors);

              let item = this.GetItemFromRow(row, sheet.name, type);

              if (mode === InventoryExcelParseMode.BulkUpdate) {
                const i: InventoryBulkUpdateItem = { ...item, itemUrl: this.GetCellValue(row, InventoryUpdateTemplateColumns.ItemURL) };
                item = i;
              }

              const duplicatedFreezerBoxCellErrors = this.GetFreezerBoxHasDuplicates(item, items, sheet.name);

              aggregator.addErrors(duplicatedFreezerBoxCellErrors);

              if (sheetErrors.length <= 0) {
                items.push(item as T);
              }
            }
          });
          aggregator.getErrors().forEach((e) => errors.push(e));
        });
      return { isValid: errors.length === 0, items, errors, fileDate };
    } catch {
      return { isValid: false, items: [], errors: [{ id: "inventory.upload.error.unable.to.parse" }], fileDate: new Date() };
    }
  }
}
