/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Link, Outlet, useLocation, useMatch, useNavigate } from "react-router";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { AuthorizedComponent } from "../components/Authentication/AuthorizedComponent";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { onPageLoad } from "../utils/onPageLoad";
import "./Management.scss";
import { getManagementLinks, isFeatureManagementEnabled } from "./ManagementLinks";

export function Management(): ReactElement {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const match = useMatch(ApplicationPaths.Management.Home);

  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.management" }));
  }, []);

  useEffect(() => {
    if (match) {
      navigate(ApplicationPaths.Management.Types);
    }
  }, [location]);

  const links = getManagementLinks();

  return (
    <AuthorizedComponent requiredPermissions={[RolePermissions.LabManagementAllowed]}>
      <Row className="management-container">
        <Col lg={2} xs={12} className="management-container-list">
          <nav className="management-nav" role={"navigation"} aria-label={intl.formatMessage({ id: "management.tools.navigation" })}>
            <ol>
              {links
                .filter((l) => isFeatureManagementEnabled(l))
                .map((link) => (
                  <li
                    key={link.path}
                    className={classnames({
                      "management-container-list-item": true,
                      active: location && location.pathname.includes(link.linkPath),
                    })}
                  >
                    <Link to={link.linkPath}>
                      <FormattedMessage id={link.messageid} />
                    </Link>
                  </li>
                ))}
            </ol>
          </nav>
        </Col>
        <Col lg={10} className="management-container-contents">
          <Outlet />
        </Col>
      </Row>
    </AuthorizedComponent>
  );
}
