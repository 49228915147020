import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Navigation } from "../components";
import { Settings } from "../utils/Settings";

export function AccessDeniedApp(): ReactElement {
  return (
    <div>
      <Navigation />
      <div className="container-fluid">
        <div>
          <FormattedMessage id="access.denied" />
        </div>
        <div>
          <a href={Settings.getAccountUrlBase()}>
            <FormattedMessage id="sign.in" />
          </a>
        </div>
      </div>
    </div>
  );
}
