import React, { ReactElement, useState } from "react";
import {
  AdvancedSearchCriteria,
  FreezerBoxDisplayFormat,
  InventorySearchCriteria,
  InventorySearchResultSetItem,
} from "@labarchives/inventory-shared/build/inventory";
import { InventoryTypeView } from "../types/views";
import * as utils from "../../utils";
import { UserView } from "../../user/types/views";
import { StorageLocationView } from "../../storage/types/views";
import { InventoryApi } from "../../api/InventoryApi";
import { InventoryExportModalComponent } from "./InventoryExportModalComponent";
import { InventoryExcel } from "./InventoryExcel";

export interface InventoryExportModalProps {
  isOpen: boolean;
  inventoryTypeViews: InventoryTypeView[];
  storage: StorageLocationView;
  userViews: UserView[];
  searchCriteria: InventorySearchCriteria;
  advancedSearchCriteria: AdvancedSearchCriteria[];
  api: InventoryApi;

  freezerBoxCellFormatter(cells: string[], format: FreezerBoxDisplayFormat | null, rows: number | null, cols: number | null): string;

  onCancel(): void;

  onExportComplete(): void;
}

export interface InventoryExportHooks {
  isExporting: boolean;

  onExportCurrentResults(): Promise<void>;

  onExportAll(): Promise<void>;
}

export interface InventoryExportHooksProps {
  inventoryTypeViews: InventoryTypeView[];
  storage: StorageLocationView;
  userViews: UserView[];
  searchCriteria: InventorySearchCriteria;
  advancedSearchCriteria: AdvancedSearchCriteria[];
  api: InventoryApi;

  freezerBoxCellFormatter(cells: string[], format: FreezerBoxDisplayFormat | null, rows: number | null, cols: number | null): string;

  onExportComplete(): void;
}

export function useInventoryExport(props: InventoryExportHooksProps): InventoryExportHooks {
  const [isExporting, setIsExporting] = useState(false);
  const { api, searchCriteria, advancedSearchCriteria, inventoryTypeViews, userViews, storage, freezerBoxCellFormatter } = props;

  async function exportItems(criteria: InventorySearchCriteria, advancedCriteria: AdvancedSearchCriteria[]): Promise<void> {
    setIsExporting(true);

    let exportedItems: InventorySearchResultSetItem[] = [];
    exportedItems = await (advancedCriteria.length === 0
      ? api.exportInventory(criteria)
      : api.exportInventoryAdvanced({ ...criteria, criteria: advancedCriteria }));
    const excel = InventoryExcel.CreateExport(exportedItems, inventoryTypeViews, userViews, storage, freezerBoxCellFormatter);

    const blob = await excel.AsBlob();
    utils.saveAs(blob, "InventoryExport.xlsx");

    setIsExporting(false);

    props.onExportComplete();
  }

  async function onExportCurrentResults(): Promise<void> {
    await exportItems(searchCriteria, advancedSearchCriteria);
  }

  async function onExportAll(): Promise<void> {
    await exportItems({ term: "", typeIds: [], locationIds: [] }, []);
  }

  return {
    isExporting,
    onExportCurrentResults,
    onExportAll,
  };
}

export function InventoryExportModal(props: InventoryExportModalProps): ReactElement {
  const { isExporting, onExportCurrentResults, onExportAll } = useInventoryExport(props);

  return (
    <InventoryExportModalComponent
      onCancel={props.onCancel}
      isOpen={props.isOpen}
      isExporting={isExporting}
      onExportAll={onExportAll}
      onExportCurrentResults={onExportCurrentResults}
    />
  );
}
