/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line max-classes-per-file
// eslint-disable-next-line max-classes-per-file
import { Settings } from "../utils/Settings";

class Management {
  public Home = "/management";
  public Types = "/management/types";
  public Users = "/management/users";
  public Settings = "/management/settings";
  public Display = "/management/display";
  public Storage = "/management/storage";
  public StorageAdd = "/management/storage/add";
  public Vendors = "/management/vendors";
  public Roles = "/management/roles";
  public BulkInventoryUpdate = "/management/inventory/bulk";
  public BulkInventoryImport = "/management/inventory/import";

  public StorageLocation(locationId: number): string {
    return `/management/storage/${locationId}`;
  }

  public Routes: any = {
    Roles: "roles",
    RoleById: "roles/:id?",
    TypeById: "types/:id?",
    StorageById: "storage/:id?",
    Users: "users",
    VendorsById: "vendors/:id?",
    Settings: "settings",
    Display: "display",
    BulkInventoryUpdate: "inventory/bulk",
    BulkInventoryImport: "inventory/import",
    Home: "/management",
  };
}

class Reports {
  public Home = "/reports";
}

class Admin {
  public Routes: any = {
    InventorySearch: "/admin/inventory",
    InventoryItem: "/admin/:accountId/inventory/:id",
    Management: {
      Home: "/admin/management",
      Locations: "/admin/management/locations",
    },
  };

  // eslint-disable-next-line class-methods-use-this
  public Item(itemId: number | string, accountId: number): string {
    return `/admin/${accountId}/inventory/${itemId}`;
  }
}

class Inventory {
  public Search = "/inventory";

  public Add = "/inventory/add";

  // eslint-disable-next-line class-methods-use-this
  public Item(itemId: number | string): string {
    return `/inventory/${itemId}`;
  }

  public GetFullUrl(itemId: number | string): string {
    return `${Settings.getBaseInventoryWebUrl()}/inventory/${itemId}`;
  }

  // eslint-disable-next-line class-methods-use-this
  public Duplicate(itemId: number | string): string {
    return `/inventory/add/${itemId}`;
  }

  // eslint-disable-next-line class-methods-use-this
  public Edit(itemId: number | string): string {
    return `/inventory/${itemId}/edit`;
  }

  public Routes: any = {
    Duplicate: "/inventory/add/:id",
    Add: "/inventory/add",
    Edit: "/inventory/:id/edit",
    Item: "/inventory/:id",
    Search: "/inventory",
  };
}

class Orders {
  public Search = "/orders";

  public Request = "/orders/add";

  // eslint-disable-next-line class-methods-use-this
  public Order(orderId: number): string {
    return `/orders/${orderId}`;
  }

  public ReorderItem(itemId: number | string): string {
    return `/orders/request/${itemId}`;
  }

  public Routes: any = {
    ReorderItem: "/orders/request/:id",
    Request: "/orders/add",
    Order: "/orders/:id",
    Search: "/orders",
  };
}

class Subscription {
  public Home = "/subscription";

  public Routes: any = {
    Home: "/subscription",
  };
}

class Storage {
  public Home = "/storage";

  public Browse(locationId: number): string {
    return `/storage/${locationId}`;
  }

  public Routes: any = {
    Home: "/storage",
    Browse: "/storage/:id",
  };
}

class Notifications {
  public Home = "/notifications";

  public Routes: any = {
    Home: "/notifications",
  };
}

class SuperUser {
  public Home = "/superuser";

  // eslint-disable-next-line class-methods-use-this
  public User(userId: number): string {
    return `/superuser/users/${userId}`;
  }

  // eslint-disable-next-line class-methods-use-this
  public Account(accountId: number): string {
    return `/superuser/accounts/${accountId}`;
  }

  public Routes: any = {
    User: "/superuser/users/:id",
    Account: "/superuser/accounts/:id",
    Home: "/superuser",
  };
}

export class ApplicationPaths {
  public static Home = "/";

  public static Logout = "/logout";

  public static Inventory: Inventory = new Inventory();

  public static Orders: Orders = new Orders();

  public static Storage: Storage = new Storage();

  public static Subscription: Subscription = new Subscription();

  public static Management: Management = new Management();

  public static Notifications: Notifications = new Notifications();

  public static SuperUser: SuperUser = new SuperUser();

  public static Admin: Admin = new Admin();

  public static Reports: Reports = new Reports();

  public static Routes: any = {
    Home: "/",
    Logout: "/logout",
  };
}
