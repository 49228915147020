import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "@labarchives/ui-design";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { Col, Row, Tooltip } from "reactstrap";
import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";
import { InventoryStandardField } from "@labarchives/inventory-shared/build/inventory";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { ManageInventoryTypeProps } from "./ManageInventoryTypeHooks";
import { useManageInventoryStandardFieldsHooks } from "./ManageInventoryStandardFieldsHooks";

export function ManageInventoryTypeStandardFields(props: ManageInventoryTypeProps): ReactElement {
  const intl = useIntl();
  const {
    isEditing,
    onEnterEdit,
    onSave,
    onCancelEdit,
    isFieldVisible,
    isFieldVisibleCheckboxDisabled,
    onFieldVisibleChanged,
    isFieldRequired,
    isFieldRequiredCheckboxDisabled,
    onFieldRequiredChanged,
  } = useManageInventoryStandardFieldsHooks(props);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = (): void => setTooltipOpen(!tooltipOpen);

  function getFieldLabelKey(field: InventoryStandardField): string {
    switch (field) {
      case InventoryStandardField.Name: {
        return "name";
      }
      case InventoryStandardField.Quantity: {
        return "quantity";
      }
      case InventoryStandardField.Location: {
        return "location";
      }
      case InventoryStandardField.Description: {
        return "description";
      }
      case InventoryStandardField.Vendor: {
        return "vendor";
      }
      case InventoryStandardField.CatalogNumber: {
        return "catalog.number";
      }
      case InventoryStandardField.LotNumber: {
        return "lot.number";
      }
      case InventoryStandardField.Price: {
        return "price";
      }
      case InventoryStandardField.DateReceived: {
        return "date.received";
      }
      case InventoryStandardField.GrantNumber: {
        return "grant.number";
      }
      case InventoryStandardField.PONumber: {
        return "po.number";
      }
      case InventoryStandardField.Expiration: {
        return "expiration";
      }
      case InventoryStandardField.SafetySheet: {
        return "safety.sheet";
      }
      case InventoryStandardField.Notes: {
        return "notes";
      }
    }
    return "";
  }

  function getFieldVisibleCheckbox(field: InventoryStandardField): ReactElement {
    return (
      <div className={"standard-field-visible-col"}>
        <CheckboxLabel>
          <CheckboxInput
            id={`standard-field-visible-${field}`}
            uncheckedValue="0"
            checked={isFieldVisible(field)}
            onChange={(e) => onFieldVisibleChanged(field, e.target.checked)}
            disabled={!isEditing || isFieldVisibleCheckboxDisabled(field)}
          />
          <span>
            <FormattedMessage id={getFieldLabelKey(field)} />
          </span>
        </CheckboxLabel>
      </div>
    );
  }

  function getFieldRequiredCheckbox(field: InventoryStandardField): ReactElement {
    return (
      <div className={"standard-field-required-col"}>
        <CheckboxLabel>
          <CheckboxInput
            id={`standard-field-required-${field}`}
            uncheckedValue="0"
            checked={isFieldRequired(field)}
            onChange={(e) => onFieldRequiredChanged(field, e.target.checked)}
            disabled={!isEditing || isFieldRequiredCheckboxDisabled(field)}
            aria-label={intl.formatMessage({ id: "required" })}
          />
          <span>&nbsp;</span>
        </CheckboxLabel>
      </div>
    );
  }

  return (
    <div id={"manage-inventory-type-standard-attributes-section"} className={"management-container-contents management-panel"}>
      <div className="manage-inventory-attributes-header-container management-name-container">
        <div className="management-item-name">
          <FormattedMessage id={"standard.fields"} />
          <Button className={"ms-2"} color={"link"} id={"standard-field-more-info"}>
            <FontAwesomeIcon icon={faCircleInfo} size="lg" />
          </Button>
          <Tooltip placement="right" isOpen={tooltipOpen} target="standard-field-more-info" toggle={toggleTooltip}>
            Select standard fields to include for this inventory type. Selected fields will be displayed for user input when adding or editing items
            of this inventory type.
            <br /> <br />
            Any existing data for a deselected field will still appear in search results.
          </Tooltip>
        </div>

        {!isEditing && (
          <div>
            <Button
              color="link"
              title={intl.formatMessage({ id: "edit" })}
              onClick={() => onEnterEdit()}
              id={"manage-inventory-standard-field-enter-edit"}
            >
              <FontAwesomeIcon icon={faPencilAlt} size="lg" />
            </Button>
          </div>
        )}

        {isEditing && (
          <div>
            <Button onClick={() => onSave()} className="me-1" color="primary" id={"manage-inventory-standard-field-save"}>
              <FormattedMessage id="save" />
            </Button>
            <CancelButton onClick={() => onCancelEdit()} id={"manage-inventory-standard-field-cancel"} />
          </div>
        )}
      </div>

      <div className={""}>
        <Row>
          <Col sm={12} md={6}>
            <Row>
              <Col>&nbsp;</Col>
              <Col className={"standard-field-required-col"}>
                <span className={"fw-bold"}>
                  <FormattedMessage id="is.required" />
                </span>
              </Col>
            </Row>
            <Row>
              <Col>{getFieldVisibleCheckbox(InventoryStandardField.Name)}</Col>
              <Col>{getFieldRequiredCheckbox(InventoryStandardField.Name)}</Col>
            </Row>
            <Row>
              <Col>{getFieldVisibleCheckbox(InventoryStandardField.Quantity)}</Col>
              <Col>{getFieldRequiredCheckbox(InventoryStandardField.Quantity)}</Col>
            </Row>
            <Row>
              <Col>{getFieldVisibleCheckbox(InventoryStandardField.Location)}</Col>
              <Col>{getFieldRequiredCheckbox(InventoryStandardField.Location)}</Col>
            </Row>
            <Row>
              <Col>{getFieldVisibleCheckbox(InventoryStandardField.Description)}</Col>
              <Col>{getFieldRequiredCheckbox(InventoryStandardField.Description)}</Col>
            </Row>
          </Col>
          <Col sm={12} md={6}>
            <Row className={"d-none d-md-flex"}>
              <Col>&nbsp;</Col>
              <Col className={"standard-field-required-col"}>
                <span className={"fw-bold"}>
                  <FormattedMessage id="is.required" />
                </span>
              </Col>
            </Row>
            <Row>
              <Col>{getFieldVisibleCheckbox(InventoryStandardField.Vendor)}</Col>
              <Col>{getFieldRequiredCheckbox(InventoryStandardField.Vendor)}</Col>
            </Row>
            <Row>
              <Col>{getFieldVisibleCheckbox(InventoryStandardField.CatalogNumber)}</Col>
              <Col>{getFieldRequiredCheckbox(InventoryStandardField.CatalogNumber)}</Col>
            </Row>
            <Row>
              <Col>{getFieldVisibleCheckbox(InventoryStandardField.LotNumber)}</Col>
              <Col>{getFieldRequiredCheckbox(InventoryStandardField.LotNumber)}</Col>
            </Row>
            <Row>
              <Col>{getFieldVisibleCheckbox(InventoryStandardField.Price)}</Col>
              <Col>{getFieldRequiredCheckbox(InventoryStandardField.Price)}</Col>
            </Row>
            <Row>
              <Col>{getFieldVisibleCheckbox(InventoryStandardField.DateReceived)}</Col>
              <Col>{getFieldRequiredCheckbox(InventoryStandardField.DateReceived)}</Col>
            </Row>
            <Row>
              <Col>{getFieldVisibleCheckbox(InventoryStandardField.GrantNumber)}</Col>
              <Col>{getFieldRequiredCheckbox(InventoryStandardField.GrantNumber)}</Col>
            </Row>
            <Row>
              <Col>{getFieldVisibleCheckbox(InventoryStandardField.PONumber)}</Col>
              <Col>{getFieldRequiredCheckbox(InventoryStandardField.PONumber)}</Col>
            </Row>
            <Row>
              <Col>{getFieldVisibleCheckbox(InventoryStandardField.Expiration)}</Col>
              <Col>{getFieldRequiredCheckbox(InventoryStandardField.Expiration)}</Col>
            </Row>
            <Row>
              <Col>{getFieldVisibleCheckbox(InventoryStandardField.SafetySheet)}</Col>
              <Col>{getFieldRequiredCheckbox(InventoryStandardField.SafetySheet)}</Col>
            </Row>
            <Row>
              <Col>{getFieldVisibleCheckbox(InventoryStandardField.Notes)}</Col>
              <Col>{getFieldRequiredCheckbox(InventoryStandardField.Notes)}</Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}
