import React, { ReactElement, useContext, useState } from "react";
import Tour, { ReactourStep } from "reactour";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/pro-light-svg-icons";
import { Button } from "@labarchives/ui-design/dist";
import { RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { AppContext } from "../../app/AppContext";
import { AuthenticationContext } from "../Authentication/AuthenticationContext";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import "./InventoryTour.scss";
import { useRouterNavigation } from "../../utils/useRouterNavigation";

export function InventoryTour(): ReactElement {
  const appState = useContext(AppContext);
  const authState = useContext(AuthenticationContext);
  const isLabManager = authState.hasPermissions([RolePermissions.LabManagementAllowed]);
  const routerNavigation = useRouterNavigation();

  const [forceUpdate, setForceUpdate] = useState("");

  const onTourComplete = (endLocation: string): void => {
    appState.setIsTourOpen(false);
    routerNavigation.navigateTo(endLocation);
  };

  function getLabManagerSteps(): ReactourStep[] {
    const initialStep: ReactourStep = {
      selector: "",
      content: (
        <div>
          <div className="inventory-tour-heading">Set up your inventory in four simple steps.</div>
          <div>
            This short tour will show how to:
            <ol>
              <li>Customize your inventory types</li>
              <li>Set up locations</li>
              <li>Add items</li>
              <li>Invite lab members</li>
            </ol>
          </div>
        </div>
      ),
      action: () => {
        routerNavigation.navigateTo(ApplicationPaths.Home);
      },
    };
    const inventoryTypesLanding: ReactourStep = {
      selector: "",
      content: (
        <div>
          <div className="inventory-tour-heading">Inventory Types are completely customizable.</div>
          <div className="inventory-tour-pad-bottom">We’ve created some default types to help you get started.</div>
          <div>See how you can modify or add your own!</div>
        </div>
      ),
      action: () => {
        routerNavigation.navigateTo(ApplicationPaths.Management.Types);
      },
    };

    const inventoryTypesReview: ReactourStep = {
      selector: "[data-tour='review-inventory-types']",
      content: (
        <div>
          <div className="inventory-tour-heading">Review and modify the default Inventory Types.</div>
          <div className="inventory-tour-pad-bottom">Click the name of the type to view item details.</div>
          <div className="inventory-tour-pad-bottom">
            Use the <FontAwesomeIcon icon={faPencilAlt} className="button-icon inventory-tour-link-color" size="lg" /> to edit and{" "}
            <FontAwesomeIcon icon={faTrash} className="button-icon inventory-tour-link-color" size="lg" /> to delete
          </div>
          <div>Note: Customize your types first so any updates will be included in your Inventory Import Template.</div>
        </div>
      ),
      action: (domNode) => {
        routerNavigation.navigateTo(ApplicationPaths.Management.Types);
        if (!domNode) {
          setForceUpdate("review-inventory-types");
        }
      },
    };

    const inventoryTypesAdd: ReactourStep = {
      selector: "[data-tour='add-inventory-types']",
      content: (
        <div>
          <div className="inventory-tour-heading">Add your custom types.</div>
          <div>Note: Changes will be included in your Inventory Import Template!</div>
        </div>
      ),
      action: (domNode) => {
        routerNavigation.navigateTo(ApplicationPaths.Management.Types);
        if (!domNode) {
          setForceUpdate("add-inventory-types");
        }
      },
    };

    const storageLocationsLanding: ReactourStep = {
      selector: "",
      content: (
        <div>
          <div className="inventory-tour-heading">Know exactly where everything is in your lab.</div>
          <div>Customize your storage locations.</div>
        </div>
      ),
      action: () => {
        routerNavigation.navigateTo(ApplicationPaths.Management.Storage);
      },
    };

    const storageLocationsAdd: ReactourStep = {
      selector: "[data-tour='add-storage']",
      style: {
        maxWidth: 1200,
      },
      content: (
        <div>
          <div className="inventory-tour-heading">Customize storage locations for your lab.</div>
          <div className="inventory-tour-pad-bottom">Step 1: Add a top-level storage location</div>
          <div className="inventory-tour-pad-screenshot">
            <img src="/images/tour/storage-location.png" alt="Storage Location" />
          </div>
        </div>
      ),
      action: () => {
        routerNavigation.navigateTo(ApplicationPaths.Management.Storage);
        setForceUpdate("storage-location");
      },
    };

    const storageSubLocationsAdd: ReactourStep = {
      selector: "[data-tour='add-storage']",
      style: {
        maxWidth: 1200,
      },
      content: (
        <div>
          <div className="inventory-tour-heading">Customize storage locations for your lab.</div>
          <div className="inventory-tour-pad-bottom">
            Step 2: Use <FontAwesomeIcon icon={faPencilAlt} className="button-icon inventory-tour-link-color" size="lg" /> to add sub-locations.
          </div>
          <div className="inventory-tour-pad-screenshot">
            <img src="/images/tour/storage-sublocation.png" alt="Storage Sublocation" />
          </div>
        </div>
      ),
      action: () => {
        routerNavigation.navigateTo(ApplicationPaths.Management.Storage);
        setForceUpdate("storage-sublocation");
      },
    };

    const inventorySearchLanding: ReactourStep = {
      selector: "",
      content: (
        <div>
          <div className="inventory-tour-heading">The Inventory page is where it all happens:</div>
          <div>Search, filter, add, view details, and more.</div>
        </div>
      ),
      action: () => {
        routerNavigation.navigateTo(ApplicationPaths.Inventory.Search);
      },
    };

    const inventorySearchAdd: ReactourStep = {
      selector: "[data-tour='add-inventory']",
      content: (
        <div>
          <div className="inventory-tour-heading">Ready to add items to inventory?</div>
          <div className="inventory-tour-pad-bottom">There are two options:</div>
          <div className="inventory-tour-pad-bottom">
            <ol>
              <li>Add items individually</li>
              <li>Import multiple items using the template</li>
            </ol>
          </div>
        </div>
      ),
      action: (domNode) => {
        routerNavigation.navigateTo(ApplicationPaths.Inventory.Search);
        if (!domNode) {
          setForceUpdate("add-inventory");
        }
      },
    };

    const inventorySearchImport: ReactourStep = {
      selector: "[data-tour='import-inventory']",
      position: "bottom",
      style: {
        maxWidth: "750px",
      },
      content: (
        <div style={{ display: "flex" }}>
          <div>
            <div className="inventory-tour-heading">Save time using the Import Template.</div>
            <div className="inventory-tour-pad-bottom">Step 1: Finish customizing Inventory Types.</div>
            <div className="inventory-tour-pad-bottom">Step 2: Download the template which includes Inventory Type customizations.</div>
            <div className="inventory-tour-pad-bottom">Step 3: Complete the template and import!</div>
          </div>
          <div className="inventory-tour-pad-screenshot inventory-tour-pad-top">
            <img src="/images/tour/inventory-import.png?v=1" alt="Inventory Import" width="300px" />
          </div>
        </div>
      ),
      action: (domNode) => {
        routerNavigation.navigateTo(ApplicationPaths.Inventory.Search);
        if (!domNode) {
          setForceUpdate("import-inventory");
        }
      },
    };

    const usersLanding: ReactourStep = {
      selector: "",
      content: (
        <div>
          <div className="inventory-tour-heading">Almost done - just add Lab Members</div>
          <div>We recommend customizing your Inventory Types and Storage Locations before inviting lab members.</div>
        </div>
      ),
      action: () => {
        routerNavigation.navigateTo(ApplicationPaths.Management.Users);
      },
    };

    const usersInvite: ReactourStep = {
      selector: "[data-tour='invite-users']",
      style: {
        maxWidth: 500,
      },
      content: (
        <div>
          <div className="inventory-tour-heading">Invite your lab members when you are ready</div>
          <div className="inventory-tour-pad-bottom">Ready, Set, Organize!</div>
          <div>
            <Button onClick={() => onTourComplete(ApplicationPaths.Management.Types)} color="link">
              Let&apos;s start by customizing your inventory types.
            </Button>
          </div>
        </div>
      ),
      action: (domNode) => {
        if (!domNode) {
          setForceUpdate("invite-users");
        }
        if (domNode) {
          domNode.click();
        }
      },
    };
    return [
      initialStep,
      inventoryTypesLanding,
      inventoryTypesReview,
      inventoryTypesAdd,
      storageLocationsLanding,
      storageLocationsAdd,
      storageSubLocationsAdd,
      inventorySearchLanding,
      inventorySearchAdd,
      inventorySearchImport,
      usersLanding,
      usersInvite,
    ];
  }

  function getLabMemberSteps(): ReactourStep[] {
    const initialStep: ReactourStep = {
      selector: "",
      content: (
        <div>
          <div className="inventory-tour-heading">Finding and requesting inventory items just got easier! </div>
          <div>
            This short tour will point out how to:
            <ol>
              <li>Find </li>
              <li>Add</li>
              <li>Request and track</li>
            </ol>
          </div>
        </div>
      ),
      action: () => {
        routerNavigation.navigateTo(ApplicationPaths.Home);
      },
    };

    const inventorySearchLanding: ReactourStep = {
      selector: "",
      content: (
        <div>
          <div className="inventory-tour-heading">This is your lab’s inventory. From here, you can find what you need in a snap.</div>
          <div>Search for items by keyword and filter by type, location, and more.</div>
        </div>
      ),
      action: () => {
        routerNavigation.navigateTo(ApplicationPaths.Inventory.Search);
      },
    };

    const inventorySearchAdd: ReactourStep = {
      selector: "[data-tour='add-inventory']",
      content: (
        <div>
          <div className="inventory-tour-heading">LabArchives Inventory makes it easy to add items from anywhere.</div>
          <div className="inventory-tour-pad-bottom">Add a new item and set notifications.</div>
          <div className="inventory-tour-pad-screenshot inventory-tour-no-border">
            <img src="/images/tour/inventory-add.png" alt="Add Inventory" />
          </div>
        </div>
      ),
      action: (domNode) => {
        routerNavigation.navigateTo(ApplicationPaths.Inventory.Search);
        if (!domNode) {
          setForceUpdate("add-inventory");
        }
      },
    };

    const ordersLanding: ReactourStep = {
      selector: "",
      content: (
        <div>
          <div className="inventory-tour-heading">Skip the emails and sticky notes when reordering.</div>
          <div>Go straight to the Orders page to request an item.</div>
        </div>
      ),
      action: () => {
        routerNavigation.navigateTo(ApplicationPaths.Orders.Search);
      },
    };

    const ordersRequest: ReactourStep = {
      selector: "[data-tour='add-order']",
      content: (
        <div>
          <div className="inventory-tour-heading">Need to order or track a request quickly?</div>
          <div className="inventory-tour-pad-bottom">You can view requests in real-time.</div>
          <div>
            <ul>
              <li>
                Send an <em>Order request</em> to your Lab Manager
              </li>
              <li>Check on order status</li>
              <li>Keep tabs on orders in one location</li>
            </ul>
          </div>
        </div>
      ),
      action: (domNode) => {
        routerNavigation.navigateTo(ApplicationPaths.Orders.Search);
        if (!domNode) {
          setForceUpdate("add-order");
        }
      },
    };

    const inventoryFinalStep: ReactourStep = {
      selector: "",
      content: (
        <div className="inventory-tour-pad-top">
          Let&apos;s get{" "}
          <Button onClick={() => onTourComplete(ApplicationPaths.Inventory.Search)} color="link">
            started
          </Button>{" "}
          by viewing your lab&apos;s inventory.
        </div>
      ),
      action: () => {
        routerNavigation.navigateTo(ApplicationPaths.Inventory.Search);
      },
    };

    return [initialStep, inventorySearchLanding, inventorySearchAdd, ordersLanding, ordersRequest, inventoryFinalStep];
  }

  function getSteps(): ReactourStep[] {
    const setMinWidth = (step: ReactourStep): ReactourStep => {
      if (!step.style) {
        return {
          ...step,
          style: {
            maxWidth: "600px",
          },
        };
      }

      return step;
    };

    if (isLabManager) {
      return getLabManagerSteps().map((element) => setMinWidth(element));
    }

    return getLabMemberSteps().map((element) => setMinWidth(element));
  }

  return (
    <Tour
      closeWithMask={false}
      showNumber={false}
      rounded={5}
      isOpen={appState.isTourOpen}
      startAt={0}
      onRequestClose={() => appState.setIsTourOpen(false)}
      steps={getSteps()}
      updateDelay={250}
      update={forceUpdate}
    />
  );
}
