import React, { Suspense } from "react";
import * as ReactDOMClient from "react-dom/client";
import { IntlProvider } from "react-intl";
import "moment/locale/en-gb";
import ReactGA from "react-ga";
import { BrowserRouter } from "react-router";
import { history } from "./app/history";
import { App } from "./app/App";
import * as serviceWorker from "./serviceWorker";
// eslint-disable-next-line import/extensions,import/no-unresolved
import messages_en_us from "./translations/en-us";
import { AuthenticationProvider } from "./components/Authentication/AuthenticationProvider";
import { ApplicationErrorProvider } from "./components/ApplicationError/ApplicationErrorProvider";
import { Settings } from "./utils/Settings";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const messages = {
  "en-US": messages_en_us,
  "en-GB": messages_en_us,
};

const supportedLocales = ["en-US", "en-GB"];
let currentLocale = supportedLocales.find((s) => s.toLocaleLowerCase() === navigator.language.toLocaleLowerCase());
if (!currentLocale) {
  console.warn("Current language not supported. Defaulting to en-US", navigator.language);
  currentLocale = "en-US";
}

function registerGoogleAnalytics() {
  const trackingId = Settings.getGoogleAnalyticsTrackingId();
  if (trackingId.length > 0) {
    ReactGA.initialize(trackingId);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
    history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
  }
}

function FullApp() {
  registerGoogleAnalytics();

  return (
    <IntlProvider defaultLocale="en-us" locale={navigator.language} messages={messages[currentLocale]}>
      <BrowserRouter>
        <AuthenticationProvider>
          <ApplicationErrorProvider>
            <App />
          </ApplicationErrorProvider>
        </AuthenticationProvider>
      </BrowserRouter>
    </IntlProvider>
  );
}

function IntegrationAppInit() {
  const IntegrationApp = React.lazy(() => import("./integration/IntegrationApp"));
  registerGoogleAnalytics();

  return (
    <IntlProvider defaultLocale="en-us" locale={currentLocale} messages={messages[currentLocale]}>
      <BrowserRouter basename={"/integration"}>
        <AuthenticationProvider>
          <ApplicationErrorProvider>
            <Suspense fallback={<div>Loading...</div>}>
              <IntegrationApp />
            </Suspense>
          </ApplicationErrorProvider>
        </AuthenticationProvider>
      </BrowserRouter>
    </IntlProvider>
  );
}

function isLoadingIntegratedComponent() {
  return document.location.href.includes(`${document.location.host}/integration/`);
}

const root = ReactDOMClient.createRoot(document.querySelector("#root"));
if (isLoadingIntegratedComponent()) {
  root.render(<IntegrationAppInit />);
} else {
  root.render(<FullApp />);
}

serviceWorker.unregister();
