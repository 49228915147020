import { FormattedDate, FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import React, { ReactElement } from "react";
import { Button } from "@labarchives/ui-design/dist";
import { Notification, NotificationType } from "@labarchives/inventory-shared/build/inventory";
import {
  ExpirationNotificationData,
  OrderApprovalNotificationData,
  OrderCancelledNotificationData,
  OrderPlacedNotificationData,
  OrderReceivedNotificationData,
  OrderRequestedNotificationData,
  ReorderNotificationData,
} from "@labarchives/inventory-shared/build/inventory/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router";
import { ApplicationPaths } from "../app/ApplicationPaths";

interface NotificationCardProps {
  notification: Notification;
  currency: string;

  onDismissNotification(id: number): void;
}

export function NotificationCard(props: NotificationCardProps): ReactElement {
  const intl = useIntl();

  function getNotificationMessage(notification: Notification): ReactElement {
    switch (notification.notificationTypeId) {
      case NotificationType.OrderRequestApproved: {
        const data: OrderApprovalNotificationData = notification.notificationData as OrderApprovalNotificationData;
        const id = data.isAutoApproved ? "notification.order.request.auto.approved" : "notification.order.request.approved";
        return (
          <>
            <FormattedHTMLMessage
              id={id}
              values={{
                orderId: data.orderId,
                inventoryName: data.inventoryName,
                quantity: data.quantity,
                dateApproved: intl.formatDate(data.dateApproved),
                dateRequested: intl.formatDate(data.dateRequested),
                approvedBy: data.approvedByName,
                path: ApplicationPaths.Orders.Order(data.orderId),
              }}
            />
            {data.notes.length > 0 && (
              <div>
                <em>{data.notes}</em>
              </div>
            )}
            <div>
              <Link to={ApplicationPaths.Orders.Order(data.orderId)}>
                <FormattedMessage id="view.order" />
              </Link>
            </div>
          </>
        );
      }
      case NotificationType.OrderCancelled: {
        const data: OrderCancelledNotificationData = notification.notificationData as OrderCancelledNotificationData;
        return (
          <>
            <FormattedHTMLMessage
              id="notification.order.cancelled"
              values={{
                orderId: data.orderId,
                inventoryName: data.inventoryName,
                dateCancelled: intl.formatDate(data.dateCancelled),
                dateRequested: intl.formatDate(data.dateRequested),
                cancelledBy: data.cancelledByName,
                path: ApplicationPaths.Orders.Order(data.orderId),
              }}
            />
            {data.notes.length > 0 && (
              <div>
                <em>{data.notes}</em>
              </div>
            )}
            <div>
              <Link to={ApplicationPaths.Orders.Order(data.orderId)}>
                <FormattedMessage id="view.order" />
              </Link>
            </div>
          </>
        );
      }
      case NotificationType.OrderPlaced: {
        const data: OrderPlacedNotificationData = notification.notificationData as OrderPlacedNotificationData;
        return (
          <>
            <FormattedHTMLMessage
              id="notification.order.placed"
              values={{
                orderId: data.orderId,
                inventoryName: data.inventoryName,
                quantity: data.quantity,
                dateOrdered: intl.formatDate(data.dateOrdered),
                dateRequested: intl.formatDate(data.dateRequested),
                orderedBy: data.orderedByName,
                path: ApplicationPaths.Orders.Order(data.orderId),
              }}
            />
            {data.notes.length > 0 && (
              <div>
                <em>{data.notes}</em>
              </div>
            )}
            <div>
              <Link to={ApplicationPaths.Orders.Order(data.orderId)}>
                <FormattedMessage id="view.order" />
              </Link>
            </div>
          </>
        );
      }
      case NotificationType.OrderReceived: {
        const data: OrderReceivedNotificationData = notification.notificationData as OrderReceivedNotificationData;
        return (
          <>
            <FormattedHTMLMessage
              id="notification.order.received"
              values={{
                orderId: data.orderId,
                inventoryName: data.inventoryName,
                quantity: data.quantity,
                dateReceived: intl.formatDate(data.dateReceived),
                dateRequested: intl.formatDate(data.dateRequested),
                receivedByName: data.receivedByName,
                inventoryId: data.inventoryId,
                path: ApplicationPaths.Orders.Order(data.orderId),
                inventoryPath: ApplicationPaths.Inventory.Item(data.inventoryId),
              }}
            />
            {data.notes.length > 0 && (
              <div>
                <em>{data.notes}</em>
              </div>
            )}
            <div>
              <Link to={ApplicationPaths.Orders.Order(data.orderId)}>
                <FormattedMessage id="view.order" />
              </Link>
              {" | "}
              <Link to={ApplicationPaths.Inventory.Item(data.inventoryId)}>
                <FormattedMessage id="view.item" />
              </Link>
            </div>
          </>
        );
      }
      case NotificationType.OrderReceivedAndDelegatedToRequester: {
        const data: OrderReceivedNotificationData = notification.notificationData as OrderReceivedNotificationData;
        return (
          <>
            <FormattedHTMLMessage
              id="notification.order.received.delegate.to.requester"
              values={{
                orderId: data.orderId,
                inventoryName: data.inventoryName,
                quantity: data.quantity,
                dateReceived: intl.formatDate(data.dateReceived),
                dateRequested: intl.formatDate(data.dateRequested),
                receivedByName: data.receivedByName,
                inventoryId: data.inventoryId,
                path: ApplicationPaths.Orders.Order(data.orderId),
                inventoryPath: ApplicationPaths.Inventory.Item(data.inventoryId),
              }}
            />
            {data.notes.length > 0 && (
              <div>
                <em>{data.notes}</em>
              </div>
            )}
            <div>
              <Link to={ApplicationPaths.Orders.Order(data.orderId)}>
                <FormattedMessage id="view.order" />
              </Link>
              {" | "}
              <Link to={ApplicationPaths.Inventory.Edit(data.inventoryId)} state={{ prevPath: location }}>
                <FormattedMessage id="view.item" />
              </Link>
            </div>
          </>
        );
      }
      case NotificationType.OrderReceivedAndDelegatedToThirdParty: {
        const data: OrderReceivedNotificationData = notification.notificationData as OrderReceivedNotificationData;
        return (
          <>
            <FormattedHTMLMessage
              id="notification.order.received.delegate.to.third.party"
              values={{
                orderId: data.orderId,
                inventoryName: data.inventoryName,
                quantity: data.quantity,
                dateReceived: intl.formatDate(data.dateReceived),
                dateRequested: intl.formatDate(data.dateRequested),
                receivedByName: data.receivedByName,
                inventoryId: data.inventoryId,
                path: ApplicationPaths.Orders.Order(data.orderId),
                inventoryPath: ApplicationPaths.Inventory.Item(data.inventoryId),
              }}
            />
            {data.notes.length > 0 && (
              <div>
                <em>{data.notes}</em>
              </div>
            )}
            <div>
              <Link to={ApplicationPaths.Orders.Order(data.orderId)}>
                <FormattedMessage id="view.order" />
              </Link>
              {" | "}
              <Link to={ApplicationPaths.Inventory.Edit(data.inventoryId)} state={{ prevPath: location }}>
                <FormattedMessage id="view.item" />
              </Link>
            </div>
          </>
        );
      }
      case NotificationType.OrderRequested: {
        const data: OrderRequestedNotificationData = notification.notificationData as OrderRequestedNotificationData;
        return (
          <>
            <FormattedHTMLMessage
              id="notification.order.requested"
              values={{
                orderId: data.orderId,
                inventoryName: data.inventoryName,
                quantity: data.quantity,
                dateRequested: intl.formatDate(data.dateRequested),
                requestedByName: data.requestedByName,
                price: intl.formatNumber(data.price, { style: "currency", currency: props.currency }),
                path: ApplicationPaths.Orders.Order(data.orderId),
              }}
            />
            {data.notes.length > 0 && (
              <div>
                <em>{data.notes}</em>
              </div>
            )}
            <div>
              <Link to={ApplicationPaths.Orders.Order(data.orderId)}>
                <FormattedMessage id="view.order" />
              </Link>
            </div>
          </>
        );
      }
      case NotificationType.ExpirationDateApproaching: {
        const data: ExpirationNotificationData = notification.notificationData as ExpirationNotificationData;
        return (
          <FormattedHTMLMessage
            id="notification.item.expiration"
            values={{
              inventoryId: data.itemId,
              inventoryName: data.itemName,
              expirationDate: intl.formatDate(data.expirationDate),
              path: ApplicationPaths.Inventory.Item(data.itemId),
            }}
          />
        );
      }
      case NotificationType.ReorderNeeded: {
        const data: ReorderNotificationData = notification.notificationData as ReorderNotificationData;
        return (
          <FormattedHTMLMessage
            id="notification.item.reorder"
            values={{
              inventoryId: data.itemId,
              inventoryName: data.itemName,
              unit: data.unit,
              quantity: data.quantityAvailable,
              path: ApplicationPaths.Inventory.Item(data.itemId),
            }}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  }

  return (
    <div className="notification-card">
      <div className="notification-card-content">
        <div className="notification-card-content-date">
          <FormattedDate value={props.notification.createdAt} day="numeric" month="numeric" year="numeric" hour="numeric" minute="numeric" />
        </div>
        <div>{getNotificationMessage(props.notification)}</div>
      </div>
      <div className="notification-card-close">
        <Button color="link" title={intl.formatMessage({ id: "dismiss" })} onClick={() => props.onDismissNotification(props.notification.id)}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
    </div>
  );
}
