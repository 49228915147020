import { FormattedDate, useIntl } from "react-intl";
import { useParams } from "react-router";
import React, { Fragment, ReactElement, useContext, useState } from "react";
import { Button, Col, Row, Table } from "reactstrap";
import { Link } from "react-router";
import { AlertWithIcon, Card, CardBody, CardHeader } from "@labarchives/ui-design";
import { SubscriptionType, UserStatus } from "@labarchives/inventory-shared/build/inventory";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { Loading } from "../components";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { InventoryApiClient } from "../api";
import { SuperUserUserAccountView } from "./views";
import { useSuperUserUserDetails } from "./SuperUserToolsUserDetailsHooks";
import { SuperUserChangeUserStatusModal } from "./SuperUserChangeUserStatusModal";
import { SuperUserPermissionModal } from "./SuperUserPermissionModal";
import { SuperUserSiteAdminModal } from "./SuperUseSiteAdminModal";

type RouteParams = {
  id: string;
};

export function SuperUserToolsUserDetails(): ReactElement {
  const { id } = useParams<RouteParams>();
  const authState = useContext(AuthenticationContext);
  const intl = useIntl();

  const userId = Number.parseInt(id || "");
  const {
    isLoading,
    userDetails,
    userNotFound,
    accountToChange,
    hasUpdateStatusError,
    showSubscriptionDetails,
    onCancelChangeStatus,
    onPromptChangeStatus,
    onChangeUserStatus,
    setHasUpdateStatusError,
    onToggleChangeSiteAdmin,
    onChangeUserSiteAdmin,
    isChangingSiteAdmin,
  } = useSuperUserUserDetails(userId, new InventoryApiClient());

  const [permissionAccount, setPermissionAccount] = useState<SuperUserUserAccountView | undefined>(undefined);

  const user = authState.getUser();
  if (!user || !user.isSuperUser) {
    return <Fragment />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (userNotFound) {
    return <div>This user does not have an inventory account</div>;
  }

  function getAccountType(a: SuperUserUserAccountView): string {
    if (a.hasSiteLicense) {
      return "Site";
    }

    if (a.subscriptionType === SubscriptionType.Free) {
      return "Free Trial";
    }

    return "Paid Subscription";
  }

  function getIsBundle(a: SuperUserUserAccountView): string {
    if (a.isBundleSubscription) {
      return " (Bundle Subscription)";
    }
    return "";
  }

  function getSeatCount(a: SuperUserUserAccountView): string {
    return a.subscriptionType === SubscriptionType.Free || a.hasSiteLicense ? "-" : a.seatCount.toString();
  }

  function getSubscriptionBegin(a: SuperUserUserAccountView): string {
    return a.hasSiteLicense ? "" : intl.formatDate(a.subscriptionBegin);
  }

  function getSubscriptionEnd(a: SuperUserUserAccountView): string {
    return a.hasSiteLicense ? "" : intl.formatDate(a.subscriptionEnd);
  }

  function getFullName(): string {
    return `${userDetails.first} ${userDetails.last}`;
  }

  function viewPermissions(a: SuperUserUserAccountView): void {
    setPermissionAccount(a);
  }

  function getSiteAdminText(): string {
    if (userDetails.isSiteAdmin) {
      return "Yes";
    }

    return "No";
  }

  return (
    <div id="super-user-user-details">
      <Row>
        <Col>
          <div>
            <Link to={ApplicationPaths.SuperUser.Home}>Return to Super User Inventory Dashboard</Link>
          </div>
          <AlertWithIcon isOpen={hasUpdateStatusError} toggle={() => setHasUpdateStatusError(false)} color="danger">
            Could not update user status. If activating, ensure the account has enough open seats.
          </AlertWithIcon>
          <Card>
            <CardHeader className={"user-details-header"}>
              <div>{getFullName()} - All Inventories</div>
              {userDetails.accounts.some((a) => a.hasSiteLicense) && (
                <div>
                  Inventory Admin?{" "}
                  <Button color={"link"} onClick={() => onToggleChangeSiteAdmin()}>
                    {getSiteAdminText()}
                  </Button>
                </div>
              )}
            </CardHeader>
            <CardBody className="superuser-user-details">
              <Table>
                <thead>
                  <tr>
                    <th>Inventory Name</th>
                    <th>Owner</th>
                    <th>Role</th>
                    <th>Account Type</th>
                    {showSubscriptionDetails && <th>Subscription Begin</th>}
                    {showSubscriptionDetails && <th>Subscription End</th>}
                    {showSubscriptionDetails && <th>Seat Count</th>}
                    <th>Inventory Creation Date</th>
                    <th>Added On</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {userDetails.accounts.map((a) => (
                    <tr key={a.id}>
                      <td>
                        <Link to={ApplicationPaths.SuperUser.Account(a.id)}>{a.name}</Link>
                      </td>
                      <td>
                        <Link to={ApplicationPaths.SuperUser.User(a.ownerId)}>{a.ownerName}</Link>
                        {userDetails.id === a.ownerId && <div>(current user)</div>}
                      </td>
                      <td>
                        {a.roleName}
                        <div>
                          <Button color="link" onClick={() => viewPermissions(a)} className={"text-left"}>
                            View Permissions
                          </Button>
                        </div>
                      </td>
                      <td>
                        {getAccountType(a)} {getIsBundle(a)}
                      </td>
                      {showSubscriptionDetails && <td>{getSubscriptionBegin(a)}</td>}
                      {showSubscriptionDetails && <td>{getSubscriptionEnd(a)}</td>}
                      {showSubscriptionDetails && <td>{getSeatCount(a)}</td>}
                      <td>
                        <FormattedDate value={a.dateCreated} />
                      </td>
                      <td>
                        <FormattedDate value={a.dateUserAdded} />
                      </td>
                      <td>
                        <Button color="link" onClick={() => onPromptChangeStatus(a.id)}>
                          {a.status === UserStatus.Active && "Active"}
                          {a.status === UserStatus.Inactive && "Inactive"}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <SuperUserChangeUserStatusModal
        isOpen={accountToChange !== undefined}
        onToggle={onCancelChangeStatus}
        userName={getFullName()}
        account={accountToChange}
        onChangeStatus={onChangeUserStatus}
      />
      <SuperUserPermissionModal
        isOpen={permissionAccount !== undefined}
        onToggle={() => setPermissionAccount(undefined)}
        account={permissionAccount}
      />
      <SuperUserSiteAdminModal
        isOpen={isChangingSiteAdmin}
        onToggle={onToggleChangeSiteAdmin}
        user={userDetails}
        onChangeUserSiteAdmin={onChangeUserSiteAdmin}
      />
    </div>
  );
}
