/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { InventorySearchAutoSuggestItem, RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { BarcodeReader, InventoryAutocomplete } from "../components";
import "./Home.css";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { AppContext } from "../app/AppContext";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { onPageLoad } from "../utils/onPageLoad";
import { InventoryApiClient } from "../api";
import { useRouterNavigation } from "../utils/useRouterNavigation";
import { FirstVisitGettingStarted } from "./FirstVisitGettingStarted";
import { GeneralGettingStarted } from "./GeneralGettingStarted";

export function Home(): ReactElement {
  const authState = useContext(AuthenticationContext);
  const appState = useContext(AppContext);
  const user = authState.getUser();
  const isLabManager = authState.hasPermissions([RolePermissions.LabManagementAllowed]);
  const accountName = !user || user.accounts.length === 1 ? undefined : user.activeAccount.name;
  const intl = useIntl();
  const api = new InventoryApiClient();
  const routerNavigation = useRouterNavigation();

  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.home" }));
  }, []);

  function onInventorySelected(item: InventorySearchAutoSuggestItem): void {
    routerNavigation.navigateTo(`/inventory/${item.id}`);
  }

  function onItemScanned(id: number | string): void {
    routerNavigation.navigateTo(`/inventory/${id}`);
  }

  function isFirstVisit(): boolean {
    return user !== undefined && user.lastLogin === null;
  }

  return (
    <div>
      <div className={"visually-hidden"}>
        <h1>
          <FormattedMessage id={"inventory.overview"} />
        </h1>
      </div>

      {accountName && (
        <Row className={"home-lab-name"}>
          <Col xs={10} xl={{ size: 9, offset: 1 }}>
            {accountName}
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={10} xl={{ size: 9, offset: 1 }} role={"search"}>
          <InventoryAutocomplete onItemSelected={onInventorySelected} clearOnSelect api={api} />
        </Col>
        <Col xs={2}>
          <BarcodeReader onItemRouted={onItemScanned} logError={api.logError} />
        </Col>
      </Row>

      <Row>
        <Col lg={12} xl={{ size: 10, offset: 1 }} className="mt-2">
          <Button color="link" tag={Link as any} {...{ to: ApplicationPaths.Inventory.Add }} id="add-to-inventory">
            <FontAwesomeIcon icon={faPlusCircle} className="button-icon" />
            <FormattedMessage id="add.to.inventory" />
          </Button>
          <hr />
        </Col>
      </Row>

      <Row>
        <Col lg={12} xl={{ size: 10, offset: 1 }}>
          {isFirstVisit() && <FirstVisitGettingStarted isLabManager={isLabManager} startTour={() => appState.setIsTourOpen(true)} />}
          {!isFirstVisit() && <GeneralGettingStarted isLabManager={isLabManager} startTour={() => appState.setIsTourOpen(true)} />}
        </Col>
      </Row>
    </div>
  );
}
