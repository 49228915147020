import { ChangeEvent, RefObject, useState } from "react";
import { CasSearchResults } from "@labarchives/inventory-shared/build/inventory";
import { CustomAttributeDefinitionView } from "../types/views";
import { InventoryApi } from "../../api/InventoryApi";

export interface CasSearchInputStatus {
  searching: boolean;
  failed: boolean;
}

export interface CasSearchInputProps {
  id: number;
  inputId: string;
  definition: CustomAttributeDefinitionView;
  isValid: boolean;
  fieldName: string;
  onChange: (fieldName: string, e: ChangeEvent<HTMLInputElement>) => void;
  innerRef?: RefObject<HTMLInputElement>;
  onCasSearched: (casSearchResult: CasSearchResults | null) => void;
  values: string[];
  isSearchEnabled: boolean;
  api: InventoryApi;
}

export interface CasSearchInputHooks {
  casOnChanged: (fieldName: string, e: ChangeEvent<HTMLInputElement>) => void;
  defaultValue: string;
  searchStatus: CasSearchInputStatus;
  onCasSearched: () => void;
}

export function useCasSearchInput(props: CasSearchInputProps): CasSearchInputHooks {
  const [cas, setCas] = useState(props.values.length > 0 ? props.values[0] : "");
  const [searchStatus, setSearchStatus] = useState({ searching: false, failed: false });
  const { isSearchEnabled, values, api, onChange } = props;

  function casOnChanged(fieldName: string, e: ChangeEvent<HTMLInputElement>): void {
    onChange(fieldName, e);
    setCas(e.target.value);
  }

  function onCasSearched(): void {
    if (!isSearchEnabled) {
      return;
    }

    const casValue = cas.trim();

    if (casValue && casValue !== "") {
      setSearchStatus({ searching: true, failed: false });
      api
        .getInventoryCASInformation(casValue)
        .then((response) => {
          if (response === null) {
            setSearchStatus({ searching: false, failed: true });
          } else {
            props.onCasSearched(response);

            setSearchStatus({ searching: false, failed: false });
          }

          return response;
        })
        .catch((error) => {
          setSearchStatus({ searching: false, failed: true });
          api.logError(error);
        });
    }
  }

  return {
    casOnChanged,
    defaultValue: values.length > 0 ? values[0] : "",
    searchStatus,
    onCasSearched,
  };
}
