import React from "react";
import moment from "moment/moment";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { InventoryStandardField, InventoryStandardFieldConfiguration } from "@labarchives/inventory-shared/build/inventory";
import isUrl from "is-url";
import { isStandardFieldEnabled } from "./selectors";
import { CustomAttributeDefinitionView, InventoryItemView } from "./types/views";
import { InputState, InventoryItemFormInputs } from "./InventoryItemEditableHooks";

export class InventoryItemEditableFormInputBuilder {
  public buildInputs(itemView: InventoryItemView, attributes: CustomAttributeDefinitionView[] = [], defaultUnits = ""): InventoryItemFormInputs {
    const inputState: InputState = {
      value: "",
      values: [],
      isValid: true,
      fieldName: "",
      required: false,
      enabled: true,
      isUrl: false,
      ref: React.createRef(),
    };

    const inputs = {
      name: {
        ...inputState,
        value: itemView.name,
        fieldName: "name",
        required: true,
        ref: React.createRef<HTMLInputElement>(),
      },
      quantityAvailable: {
        ...inputState,
        value: itemView.quantityAvailable.toString(),
        fieldName: "quantityAvailable",
        required: true,
        ref: React.createRef<HTMLInputElement>(),
      },
      unitOfMeasure: {
        ...inputState,
        value: itemView.unitOfMeasure.toString() || defaultUnits,
        fieldName: "unitOfMeasure",
        required: true,
        ref: React.createRef<HTMLInputElement>(),
      },
      storageLocation: {
        ...inputState,
        value: itemView.storageLocationId?.toString() || undefined,
        fieldName: "storageLocation",
        ref: React.createRef<HTMLInputElement>(),
      },
      expirationDate: {
        ...inputState,
        value: itemView.expirationDate ? moment(itemView.expirationDate).format(clock.STANDARD_MOMENT_DATE_FORMAT) : "",
        fieldName: "expirationDate",
        ref: React.createRef<HTMLInputElement>(),
      },
      description: {
        ...inputState,
        value: itemView.description,
        fieldName: "description",
        ref: React.createRef<HTMLInputElement>(),
      },
      vendor: {
        ...inputState,
        value: itemView.vendorId ? itemView.vendorId.toString() : "",
        fieldName: "vendor",
        ref: React.createRef<HTMLInputElement>(),
      },
      notes: {
        ...inputState,
        value: itemView.notes,
        fieldName: "notes",
        ref: React.createRef<HTMLInputElement>(),
      },
      typeId: {
        ...inputState,
        value: itemView.typeId.toString(),
        fieldName: "typeId",
        required: true,
        ref: React.createRef<HTMLInputElement>(),
      },
      catalogNumber: {
        ...inputState,
        value: itemView.catalogNumber,
        fieldName: "catalogNumber",
        ref: React.createRef<HTMLInputElement>(),
      },
      lotNumber: {
        ...inputState,
        value: itemView.lotNumber,
        fieldName: "lotNumber",
        ref: React.createRef<HTMLInputElement>(),
      },
      grantNumber: {
        ...inputState,
        value: itemView.grantNumber,
        fieldName: "grantNumber",
        ref: React.createRef<HTMLInputElement>(),
      },
      poNumber: {
        ...inputState,
        value: itemView.poNumber,
        fieldName: "poNumber",
        ref: React.createRef<HTMLInputElement>(),
      },
      price: {
        ...inputState,
        value: itemView.price ? itemView.price.toString() : "",
        fieldName: "price",
        ref: React.createRef<HTMLInputElement>(),
      },
      receivedDate: {
        ...inputState,
        value: itemView.dateReceived ? moment(itemView.dateReceived).format(clock.STANDARD_MOMENT_DATE_FORMAT) : "",
        fieldName: "receivedDate",
        ref: React.createRef<HTMLInputElement>(),
      },
      safetySheet: {
        ...inputState,
        value: itemView.safetySheetUrl,
        fieldName: "safetySheet",
        isUrl: true,
        ref: React.createRef<HTMLInputElement>(),
      },

      dynamic: this.getDynamicValues(attributes, itemView),
    };

    return this.bindStandardFieldConfiguration(itemView.inventoryStandardFieldConfiguration, inputs);
  }

  private bindStandardFieldConfiguration(
    inventoryStandardFieldConfiguration: InventoryStandardFieldConfiguration[],
    inputs: InventoryItemFormInputs,
  ): InventoryItemFormInputs {
    function isEnabled(field: InventoryStandardField): boolean {
      return isStandardFieldEnabled(field, inventoryStandardFieldConfiguration);
    }

    function isRequired(field: InventoryStandardField): boolean {
      return isEnabled(field) && inventoryStandardFieldConfiguration.find((c) => c.field === field)?.required === true;
    }

    const copy = { ...inputs };
    copy.storageLocation.required = isRequired(InventoryStandardField.Location);
    copy.storageLocation.enabled = isEnabled(InventoryStandardField.Location);

    copy.expirationDate.required = isRequired(InventoryStandardField.Expiration);
    copy.expirationDate.enabled = isEnabled(InventoryStandardField.Expiration);

    copy.description.required = isRequired(InventoryStandardField.Description);
    copy.description.enabled = isEnabled(InventoryStandardField.Description);

    copy.vendor.required = isRequired(InventoryStandardField.Vendor);
    copy.vendor.enabled = isEnabled(InventoryStandardField.Vendor);

    copy.notes.required = isRequired(InventoryStandardField.Notes);
    copy.notes.enabled = isEnabled(InventoryStandardField.Notes);

    copy.catalogNumber.required = isRequired(InventoryStandardField.CatalogNumber);
    copy.catalogNumber.enabled = isEnabled(InventoryStandardField.CatalogNumber);

    copy.lotNumber.required = isRequired(InventoryStandardField.LotNumber);
    copy.lotNumber.enabled = isEnabled(InventoryStandardField.LotNumber);

    copy.grantNumber.required = isRequired(InventoryStandardField.GrantNumber);
    copy.grantNumber.enabled = isEnabled(InventoryStandardField.GrantNumber);

    copy.poNumber.required = isRequired(InventoryStandardField.PONumber);
    copy.poNumber.enabled = isEnabled(InventoryStandardField.PONumber);

    copy.price.required = isRequired(InventoryStandardField.Price);
    copy.price.enabled = isEnabled(InventoryStandardField.Price);

    copy.receivedDate.required = isRequired(InventoryStandardField.DateReceived);
    copy.receivedDate.enabled = isEnabled(InventoryStandardField.DateReceived);

    copy.safetySheet.required = isRequired(InventoryStandardField.SafetySheet);
    copy.safetySheet.enabled = isEnabled(InventoryStandardField.SafetySheet);

    return copy;
  }

  // eslint-disable-next-line class-methods-use-this
  public getDynamicValues(
    attributes: CustomAttributeDefinitionView[],
    view: InventoryItemView,
  ): {
    [id: string]: InputState;
  } {
    const dynamic: { [id: string]: InputState } = {};

    attributes.forEach((att: CustomAttributeDefinitionView) => {
      const attributeValue = view.attributes.find((attribute) => attribute.id === att.id);
      dynamic[att.inputId] = {
        value: undefined,
        values: attributeValue ? attributeValue.values : [],
        fieldName: att.inputId,
        required: att.required,
        isValid: true,
        isUrl: false,
        enabled: true,
        ref: React.createRef(),
      };
    });

    return dynamic;
  }

  public isValid(input: InputState, value: string | string[]): boolean {
    if (!input.enabled) {
      return true;
    }

    if (Array.isArray(value)) {
      return !input.required || value.some((v) => v.trim() !== "");
    }

    if (input.required) {
      return value.trim() !== "";
    }

    if (input.isUrl && value.trim() !== "") {
      return isUrl(value.trim());
    }

    return true;
  }

  public getValue(input: InputState): string {
    return input.value ?? "";
  }

  public getValues(input: InputState): string[] {
    if (input.values && input.values.length > 0) {
      return input.values;
    }
    return [];
  }

  public getUpdatedInputState(prevField: InputState, value: string): InputState {
    return {
      ...prevField,
      value,
      isValid: this.isValid(prevField, value),
    };
  }

  public getUpdatedInputStateMultiple(prevField: InputState, values: string | string[]): InputState {
    return {
      ...prevField,
      values: Array.isArray(values) ? values : [values],
      isValid: this.isValid(prevField, values),
    };
  }

  public updateStandardFieldConfiguration(
    inputs: InventoryItemFormInputs,
    updatedFormConfig: InventoryStandardFieldConfiguration[],
  ): InventoryItemFormInputs {
    return this.bindStandardFieldConfiguration(updatedFormConfig, inputs);
  }
}
