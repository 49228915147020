import React, { ReactElement, useContext } from "react";
import { Route, Routes } from "react-router";
import { FormattedDate, FormattedMessage } from "react-intl";
import { Link } from "react-router";
import { Navigation } from "../components";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { SubscriptionManagement } from "../subscription/SubscriptionManagement";
import { ErrorBoundary } from "../components/ErrorBoundary/ErrorBoundary";
import { UsersProvider } from "../user/UsersProvider";
import { Logout } from "../components/Authentication/Logout";
import { AppProvider } from "./AppProvider";
import { ApplicationPaths } from "./ApplicationPaths";

export function SubscriptionExpiredApp(): ReactElement {
  const auth = useContext(AuthenticationContext);
  const user = auth.getUser();

  const SubscriptionExpiredNotification = (): ReactElement => {
    if (user === undefined) {
      return <div />;
    }

    return (
      <div>
        <div>
          <FormattedMessage id="your.subscription.expired.on" /> <FormattedDate value={user.activeAccount.subscriptionEnd} />
        </div>
        {user.isAccountOwner && (
          <div>
            <Link to={ApplicationPaths.Subscription.Home}>
              <FormattedMessage id="manage.your.subscription" />
            </Link>
          </div>
        )}
      </div>
    );
  };

  return (
    <ErrorBoundary>
      <AppProvider>
        <UsersProvider>
          <div>
            <Navigation />
            <div className="container-fluid">
              <Routes>
                <Route path={ApplicationPaths.Routes.Logout} element={<Logout />} />
                <Route path={ApplicationPaths.Subscription.Home} element={<SubscriptionManagement />} />
                <Route path={"*"} element={<SubscriptionExpiredNotification />} />
              </Routes>
            </div>
          </div>
        </UsersProvider>
      </AppProvider>
    </ErrorBoundary>
  );
}
