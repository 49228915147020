import { Button, Modal } from "@labarchives/ui-design";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "./AuthenticationContext";
import { AuthenticationState } from "./AuthenticationState";

export interface SessionTimeoutHooks {
  notificationShown: boolean;
  onToggle: () => void;
  onExtendSession: () => void;
}

export function useSessionTimeout(authState: AuthenticationState): SessionTimeoutHooks {
  const [notificationShown, setNotificationShown] = useState(false);
  function onExtendSession(): void {
    authState.extendSession().then(() => {
      setNotificationShown(false);
      return;
    });
  }

  function onToggle(): void {
    setNotificationShown(false);
  }

  useEffect(() => {
    if (authState.isSessionExpiring()) {
      setNotificationShown(true);
    }
  }, [authState.isSessionExpiring()]);

  return {
    onExtendSession,
    onToggle,
    notificationShown,
  };
}

export function SessionTimeoutModal(): ReactElement {
  const authContext = useContext(AuthenticationContext);
  const { notificationShown, onToggle, onExtendSession } = useSessionTimeout(authContext);
  return (
    <Modal isOpen={notificationShown} toggle={onToggle} id={"session-timeout-notification-modal"}>
      <ModalHeader toggle={onToggle}>
        <FormattedMessage id="session.ending.header" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id={"session.about.to.end"} />
      </ModalBody>
      <ModalFooter>
        <Button className="me-1" type="button" color="primary" onClick={onExtendSession}>
          <FormattedMessage id={"continue.working"} />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
