import { Notification, User } from "@labarchives/inventory-shared/build/inventory";
import { createContext, useEffect, useState } from "react";
import { InventoryApi } from "../api/InventoryApi";

export interface NotificationsState {
  notificationCount: number;

  notifications: Notification[];

  onDismissNotification(notificationId: number): void;

  onDismissAllNotifications(): void;

  onNotificationsFetched(): Promise<void>;
}

export function useNotificationsContext(api: InventoryApi, user: User | undefined): NotificationsState {
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const oneMinute = 60_000;

  function markNotificationsAsRead(ids: number[]): Promise<Notification[]> {
    return api.markNotificationsAsRead(ids).then((updatedNotifications) => {
      const unreadNotifications = notifications.filter((n) => !ids.includes(n.id));
      setNotifications(unreadNotifications);
      setNotificationCount(unreadNotifications.length);
      return updatedNotifications;
    });
  }

  async function onNotificationsFetched(): Promise<void> {
    const unread = await api.getUnreadNotifications();
    setNotificationCount(unread.length);
    setNotifications(unread);
  }

  const onDismissNotification = (notificationId: number): void => {
    markNotificationsAsRead([notificationId]).catch((error) => api.logError(error));
  };

  const onDismissAllNotifications = (): void => {
    markNotificationsAsRead(notifications.map((n) => n.id)).catch((error) => api.logError(error));
  };

  function getUnreadCount(): void {
    if (!user) {
      return;
    }
    api
      .getUnreadNotificationCount(user.id, user.activeAccountId)
      .then((n) => {
        setNotificationCount(n);
        return n;
      })
      .catch((error) => api.logError(error));
  }

  useEffect(() => {
    const timeoutId = setInterval(() => {
      getUnreadCount();
    }, oneMinute);

    getUnreadCount();
    return () => clearInterval(timeoutId);
  }, [oneMinute]);

  return {
    notificationCount,
    notifications,
    onDismissNotification,
    onDismissAllNotifications,
    onNotificationsFetched,
  };
}

const noopDefault = (): void => {
  // no-op default
};

export const NotificationsContext = createContext<NotificationsState>({
  notifications: [],
  notificationCount: 0,
  onDismissAllNotifications: noopDefault,
  onDismissNotification: noopDefault,
  onNotificationsFetched: async () => noopDefault(),
});
