import React, { ReactElement } from "react";
import { ErrorBoundary } from "../components/ErrorBoundary/ErrorBoundary";
import { AppProvider } from "./AppProvider";
import { InventoryApplicationHarness } from "./InventoryApplicationHarness";

export function AuthenticatedApp(): ReactElement {
  return (
    <ErrorBoundary>
      <AppProvider>
        <InventoryApplicationHarness />
      </AppProvider>
    </ErrorBoundary>
  );
}
