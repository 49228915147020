import { LoginNotifications, User } from "@labarchives/inventory-shared/build/inventory";
import React, { ReactElement, useContext, useState } from "react";
import { Modal, Button } from "@labarchives/ui-design";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { AuthenticationContext } from "../Authentication/AuthenticationContext";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import { useRouterNavigation } from "../../utils/useRouterNavigation";

export interface LoginNotificationHooks {
  notificationShown: LoginNotifications | undefined;

  dismiss(notification: LoginNotifications): void;
}

export function useLoginNotification(user: User | undefined): LoginNotificationHooks {
  function defaultNotification(): LoginNotifications | undefined {
    if (!user || !user.loginNotifications || user.loginNotifications.length === 0) {
      return undefined;
    }

    return user.loginNotifications[0];
  }

  const [notificationShown, setNotificationShown] = useState(defaultNotification());

  function dismiss(): void {
    setNotificationShown(undefined);
  }

  return {
    notificationShown,
    dismiss,
  };
}

export function LoginNotificationModal(): ReactElement {
  const authenticationState = useContext(AuthenticationContext);
  const { notificationShown, dismiss } = useLoginNotification(authenticationState.getUser());
  const routerNavigation = useRouterNavigation();

  const onToggle = (): void => {
    if (notificationShown) {
      dismiss(notificationShown);
    }
  };

  const onUpdateSettings = (): void => {
    routerNavigation.navigateTo(ApplicationPaths.Management.Settings);
    onToggle();
  };

  return (
    <Modal isOpen={notificationShown} toggle={onToggle} id={"login-notification-modal"}>
      <ModalHeader toggle={onToggle}>
        <FormattedMessage id="update.location.info.header" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id={"update.location.info.message"} />
      </ModalBody>
      <ModalFooter>
        <Button className="me-1" type="button" color="primary" id="login-notification-action" onClick={onUpdateSettings}>
          <FormattedMessage id={"update.location.info.button"} />
        </Button>
        <Button id={"login-notification-remind-me-later"} color="none" outline onClick={onToggle}>
          <FormattedMessage id="remind.me.later" />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
