import React, { ReactElement, useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import { Button } from "@labarchives/ui-design";
import { Loading } from "../components";
import { UsersContext } from "../user/UsersContext";
import { VendorsContext } from "../vendors/VendorsContext";
import { InventoryTypesContext } from "../inventorytypes/InventoryTypesContext";
import { StorageContext } from "../storage/StorageContext";
import "./Inventory.scss";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { onPageLoad } from "../utils/onPageLoad";
import { InventoryApiClient } from "../api";
import { useRouterNavigation } from "../utils/useRouterNavigation";
import { useInventoryItemDisplay } from "./InventoryItemDisplayHooks";
import { DisplayItemDetails } from "./DisplayItemDetails";
import { ActionRow } from "./actionrow/ActionRow";

type RouteParams = {
  id: string;
};

export function ViewInventory(): ReactElement {
  const { id } = useParams<RouteParams>();
  const userState = useContext(UsersContext);
  const vendorState = useContext(VendorsContext);
  const inventoryTypesState = useContext(InventoryTypesContext);
  const storageState = useContext(StorageContext);
  const authState = useContext(AuthenticationContext);
  const intl = useIntl();
  const api = new InventoryApiClient();

  const inventoryId = id || "";
  const {
    isLoading,
    units,
    currentItemView,
    onInventoryUsed,
    onInventoryStorageChanged,
    onDownloadAttachment,
    onQuantityChanged,
    onClose,
    storageLocations,
    isStorageLocationRequired,
  } = useInventoryItemDisplay(inventoryId, userState, inventoryTypesState, storageState, vendorState, authState, api, useRouterNavigation());

  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.inventory.view" }));
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!currentItemView.id) {
    return (
      <div className="item-not-found">
        <FormattedMessage id="item.not.found" />
      </div>
    );
  }

  return (
    <div id="inventory-item-display-container">
      <div className="faux-modal-header">
        <h1 className="faux-modal-title" id="inventory-item-display-name">
          {currentItemView.name}
        </h1>

        <div className="faux-modal-close">
          <Button type="button" onClick={onClose} aria-label={intl.formatMessage({ id: "back" })}>
            ×
          </Button>
        </div>

        <ActionRow
          currentItemView={currentItemView}
          onInventoryUsed={onInventoryUsed}
          storageLocations={storageLocations}
          units={units}
          defaultUsageAmount={"0"}
          defaultUsageUnit={currentItemView.unitOfMeasure}
          currency={authState.getCurrency()}
          api={api}
        />
      </div>
      <div className="faux-modal-body" id="inventory-body">
        <DisplayItemDetails
          currentItemView={currentItemView}
          onDownloadAttachment={onDownloadAttachment}
          onInventoryStorageChanged={onInventoryStorageChanged}
          onQuantityChanged={onQuantityChanged}
          storageLocations={storageLocations}
          units={units}
          currency={authState.getCurrency()}
          api={api}
          isStorageLocationRequired={isStorageLocationRequired}
        />
      </div>
      <div className="faux-modal-footer">
        <Button className="me-1" onClick={onClose} color="primary">
          <FormattedMessage id="back" />
        </Button>
      </div>
    </div>
  );
}
