import { FormattedMessage, useIntl } from "react-intl";
import React, { ReactElement, useEffect } from "react";
import { Link, Outlet, useLocation, useMatch, useNavigate } from "react-router";
import { Col, Row } from "reactstrap";
import classnames from "classnames";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import { onPageLoad } from "../../utils/onPageLoad";

export function AdminViewManagement(): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const match = useMatch(ApplicationPaths.Admin.Routes.Management.Home);

  const intl = useIntl();

  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.management" }));
  }, []);

  useEffect(() => {
    if (match) {
      navigate(ApplicationPaths.Admin.Routes.Management.Locations);
    }
  }, [location]);

  return (
    <Row className="management-container">
      <Col lg={2} xs={12} className="management-container-list">
        <nav className="management-nav">
          <ol>
            <li
              key={""}
              className={classnames({
                "management-container-list-item": true,
                active: location && location.pathname.includes(ApplicationPaths.Admin.Routes.Management.Locations),
              })}
            >
              <Link to={ApplicationPaths.Admin.Routes.Management.Locations}>
                <FormattedMessage id={"admin.manage.locations"} />
              </Link>
            </li>
          </ol>
        </nav>
      </Col>
      <Col lg={10} className="management-container-contents">
        <Outlet />
      </Col>
    </Row>
  );
}
