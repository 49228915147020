import React from "react";
import { ManageInventoryTypes } from "../inventorytypes/management/ManageInventoryTypes";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { ManageStorageComponent } from "../storage/management/ManageStorageComponent";
import { ManageUsers } from "../user/management/ManageUsers";
import { ManageRoles } from "../roles/management/ManageRoles";
import { ManageVendors } from "../vendors/management/ManageVendors";
import { Settings } from "./LabSettings/Settings";
import { DisplayComponent } from "./DisplaySettings/DisplayComponent";
import { BulkInventoryImportComponent } from "./BulkInventoryImport/BulkInventoryImportComponent";
import { BulkInventoryUpdateComponent } from "./BulkInventoryUpdate/BulkInventoryUpdateComponent";

interface ManagementLink {
  component: React.ComponentType<any>;
  path: string;
  messageid: string;
  linkPath: string;
  isIndex: boolean;
}

const links: ManagementLink[] = [
  {
    component: ManageInventoryTypes,
    path: ApplicationPaths.Management.Routes.TypeById,
    linkPath: ApplicationPaths.Management.Types,
    messageid: "types",
    isIndex: true,
  },
  {
    component: ManageStorageComponent,
    path: ApplicationPaths.Management.Routes.StorageById,
    linkPath: ApplicationPaths.Management.Storage,
    messageid: "storage",
    isIndex: false,
  },
  {
    component: ManageUsers,
    path: ApplicationPaths.Management.Routes.Users,
    linkPath: ApplicationPaths.Management.Users,
    messageid: "users",
    isIndex: false,
  },
  {
    component: ManageRoles,
    path: ApplicationPaths.Management.Routes.Roles,
    linkPath: ApplicationPaths.Management.Roles,
    messageid: "roles",
    isIndex: false,
  },
  {
    component: ManageVendors,
    path: ApplicationPaths.Management.Routes.VendorsById,
    linkPath: ApplicationPaths.Management.Vendors,
    messageid: "vendors",
    isIndex: false,
  },
  {
    component: Settings,
    path: ApplicationPaths.Management.Routes.Settings,
    linkPath: ApplicationPaths.Management.Settings,
    messageid: "settings",
    isIndex: false,
  },
  {
    component: DisplayComponent,
    path: ApplicationPaths.Management.Routes.Display,
    linkPath: ApplicationPaths.Management.Display,
    messageid: "display.settings",
    isIndex: false,
  },
  {
    component: BulkInventoryImportComponent,
    path: ApplicationPaths.Management.Routes.BulkInventoryImport,
    linkPath: ApplicationPaths.Management.BulkInventoryImport,
    messageid: "import.items",
    isIndex: false,
  },
  {
    component: BulkInventoryUpdateComponent,
    path: ApplicationPaths.Management.Routes.BulkInventoryUpdate,
    linkPath: ApplicationPaths.Management.BulkInventoryUpdate,
    messageid: "bulk.inventory.update",
    isIndex: false,
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function isFeatureManagementEnabled(link: ManagementLink): boolean {
  return true;
}

export function getManagementLinks(): ManagementLink[] {
  return links;
}
