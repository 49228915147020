import React, { ReactElement, useContext } from "react";
import classnames from "classnames";
import { useParams } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { Badge, Col, Row } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons/faPlusCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loading } from "../../components";
import { StorageState } from "../types/state";
import { StorageContext } from "../StorageContext";
import { parseIntOrUndefined } from "../../utils";
import { getStorageLocationView } from "../selectors";
import { useRouterNavigation } from "../../utils/useRouterNavigation";
import { AddNewLocation } from "./AddNewLocation";
import { ManageStorageLocation } from "./ManageStorageLocation";
import { ManageStorageProps, useManageStorage } from "./ManageStorageHooks";
import { isLocationNameDuplicated } from "./isLocationNameDuplicated";

type RouteParams = {
  id: string;
};

export function ManageStorageComponent(): ReactElement {
  function getManageStorageComponentProps(id: string): ManageStorageProps {
    return {
      isAdding: id === "add",
      id: parseIntOrUndefined(id),
    };
  }

  const { id } = useParams<RouteParams>();
  const state: StorageState = useContext<StorageState>(StorageContext);

  const routerNavigationWrapper = useRouterNavigation();
  const {
    isLoading,
    storageLocations,
    isAdding,
    onShowAddLocation,
    onShowEditLocation,
    activeLocationId,
    onAddLocation,
    onUpdateLocation,
    onDeleteLocation,
    onCancelAdd,
    onDuplicateStorageLocation,
  } = useManageStorage(getManageStorageComponentProps(id || ""), state, routerNavigationWrapper);

  const intl = useIntl();

  if (isLoading) {
    return <Loading />;
  }

  function checkDupes(name: string, id: number, parentId: number | null): boolean {
    return isLocationNameDuplicated(getStorageLocationView(state), name, id, parentId);
  }

  return (
    <div>
      <div className="management-header">
        <div className={"d-flex align-items-center"}>
          <h1 className="inventory-heading">
            <FormattedMessage id="storage" />
          </h1>
          <Badge role={"status"} aria-label={intl.formatMessage({ id: "manage.storage.locations.badge" }, { count: storageLocations.length })}>
            {storageLocations.length}
          </Badge>
        </div>

        <Button
          className="management-header-action"
          color="link"
          onClick={() => onShowAddLocation()}
          data-tour="add-storage"
          id="add-storage-trigger"
        >
          <FontAwesomeIcon icon={faPlusCircle} className="button-icon" />
          <FormattedMessage id="add.new.location" />
        </Button>
      </div>
      <Row>
        <Col xs={4} id="manage-storage-location-location-list">
          {storageLocations.map((item) => (
            <div key={item.location.id} className={classnames("management-container-list-item", { active: activeLocationId === item.location.id })}>
              <Button color="link" onClick={() => onShowEditLocation(item)} className="manage-storage-location-location-list-item">
                {item.location.name}
              </Button>
            </div>
          ))}
        </Col>
        <Col className="manage-storage-location">
          {isAdding && <AddNewLocation onAddLocation={onAddLocation} onCancel={onCancelAdd} isLocationNameDuplicated={checkDupes} />}
          <ManageStorageLocation
            storageLocation={storageLocations.find((i) => i.location.id === activeLocationId)}
            storageLocations={storageLocations}
            onLocationAdded={onAddLocation}
            onLocationUpdated={onUpdateLocation}
            onLocationDeleted={onDeleteLocation}
            isLocationNameDuplicated={checkDupes}
            onDuplicateStorageLocation={onDuplicateStorageLocation}
          />
        </Col>
      </Row>
    </div>
  );
}
