import React, { ReactElement } from "react";
import { InventoryApiClient } from "../../api";
import { useRouterNavigation } from "../../utils/useRouterNavigation";
import { AuthenticationContext, useAuthenticationContext } from "./AuthenticationContext";
import { getAuthenticationService } from "./getAuthenticationService";

export const AuthenticationProvider = (props: React.PropsWithChildren<unknown>): ReactElement => {
  const authenticationContextValue = useAuthenticationContext(getAuthenticationService(), new InventoryApiClient(), useRouterNavigation());
  return <AuthenticationContext.Provider value={authenticationContextValue}>{props.children}</AuthenticationContext.Provider>;
};
