import React, { RefObject, useEffect, useState } from "react";
import { InventoryItem, Order, OrderReceivedRequest, OrderStatus, OrderUpdateRequest } from "@labarchives/inventory-shared/build/inventory";
import moment from "moment";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { getUnits } from "@labarchives/inventory-shared/build/util/units";
import { AuthenticationState } from "../components/Authentication/AuthenticationState";
import { InventoryTypesState } from "../inventorytypes/types/state";
import { StorageState } from "../storage/types/state";
import { getInventoryTypeViews, isInventoryStorageLocationRequired } from "../inventorytypes/selectors";
import { getStorageLocationView } from "../storage/selectors";
import { InventoryTypeView } from "../inventory/types/views";
import { StorageLocationView } from "../storage/types/views";
import { isNotFoundError } from "../utils/errorHelpers";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { InventoryApi } from "../api/InventoryApi";
import { RouterNavigationHooks } from "../utils/useRouterNavigation";
import { getDefaultOrderView, getOrderView } from "./selectors";
import { OrderView } from "./types/views";

type OnFormInvalid = (firstInvalidInput: HTMLInputElement) => void;

interface InputState {
  isValid: boolean;
  fieldName: string;
  value: string;
  required: boolean;
  ref: RefObject<HTMLInputElement>;
}

interface FormInputs {
  [id: string]: InputState;

  inventoryName: InputState;
  inventoryTypeId: InputState;
  quantity: InputState;
  price: InputState;
  vendorId: InputState;
  catalogNumber: InputState;
  poNumber: InputState;
  grantNumber: InputState;
  notes: InputState;
  requiredDate: InputState;
}

export interface OrderHooks {
  orderView: OrderView;
  isLoading: boolean;
  formInputs: FormInputs;
  totalPrice: number;
  isAddingNote: boolean;
  isUpdatingOrder: boolean;
  openModalId: string;
  units: string[];
  isSuccessAlertOpen: boolean;
  inventoryTypeViews: InventoryTypeView[];
  storageLocations: StorageLocationView;
  isStorageLocationRequired: boolean;

  setIsSuccessAlertOpen(isOpen: boolean): void;

  setOrderView(orderView: OrderView): void;

  setIsLoading(isLoading: boolean): void;

  setFormInputs(formInputs: FormInputs): void;

  setTotalPrice(totalPrice: number): void;

  toggleAddNote(): void;

  isModalOpen(modalId: string, openStatus: OrderStatus): boolean;

  toggleModal(modalId: string): void;

  onInputChange(fieldName: string, value: string): void;

  onOrderSubmitted(onFormInvalid: OnFormInvalid): void;

  onNoteAdded(note: string): Promise<void>;

  onOrderApproval(id: number, notes: string): Promise<void>;

  onOrderPlaced(id: number, notes: string, quantityOrdered: number, pricePaid: number): Promise<void>;

  onOrderReceived(
    id: number,
    addToInventory: boolean,
    quantity: number,
    units: string,
    notes: string,
    locationId: number | null,
    storageCells: string[] | null,
    storageNotes: string | null,
    updateAfterReceive: boolean,
    delegateToId: number | null,
  ): Promise<void>;

  onOrderCancelled(id: number, notes: string): Promise<void>;
}

export function useOrder(
  orderId: number,
  authState: AuthenticationState,
  inventoryTypesState: InventoryTypesState,
  storageState: StorageState,
  api: InventoryApi,
  routerNavigation: RouterNavigationHooks,
): OrderHooks {
  const [orderView, setOrderView] = useState<OrderView>(getDefaultOrderView());
  const [isLoading, setIsLoading] = useState(true);
  const [sourceInventoryItem, setSourceInventoryItem] = useState<InventoryItem | undefined>(undefined);
  const [totalPrice, setTotalPrice] = useState(0);
  const [formInputs, setFormInputs] = useState(getFormInputs(orderView));
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
  const [openModalId, setOpenModalId] = useState("");
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);

  function getFormInputs(order: OrderView): FormInputs {
    const inputState: InputState = {
      value: "",
      isValid: true,
      fieldName: "",
      required: false,
      ref: React.createRef(),
    };

    return {
      catalogNumber: { ...inputState, fieldName: "catalogNumber", value: order.catalogNumber, required: true, ref: React.createRef() },
      vendorId: { ...inputState, fieldName: "vendorId", value: order.vendorId.toString(), required: true, ref: React.createRef() },
      poNumber: { ...inputState, fieldName: "poNumber", value: order.poNumber, ref: React.createRef() },
      grantNumber: { ...inputState, fieldName: "grantNumber", value: order.grantNumber, ref: React.createRef() },
      notes: { ...inputState, fieldName: "notes", value: "", ref: React.createRef() },
      quantity: { ...inputState, fieldName: "quantity", value: order.quantity.toString(), required: true, ref: React.createRef() },
      price: { ...inputState, fieldName: "price", value: order.price.toString(), required: true, ref: React.createRef() },
      inventoryName: { ...inputState, fieldName: "inventoryName", value: order.inventoryName, required: true, ref: React.createRef() },
      inventoryTypeId: {
        ...inputState,
        fieldName: "inventoryTypeId",
        value: order.inventoryTypeId.toString(),
        required: true,
        ref: React.createRef(),
      },
      requiredDate: {
        ...inputState,
        fieldName: "requiredDate",
        value: order.requiredDate ? moment(order.requiredDate).format(clock.STANDARD_MOMENT_DATE_FORMAT) : "",
        ref: React.createRef(),
      },
    };
  }

  function updateOrderView(order: Order): void {
    const view = getOrderView(order, sourceInventoryItem);
    setOrderView(view);
    setFormInputs(getFormInputs(view));
    setTotalPrice(view.price * view.quantity);
  }

  async function onNoteAdded(note: string): Promise<void> {
    const order = await api.addOrderNote(orderView.id, note, authState.getUser());
    updateOrderView(order);
    toggleAddNote();
  }

  function toggleAddNote(): void {
    setIsAddingNote(!isAddingNote);
  }

  async function onOrderApproval(id: number, notes: string): Promise<void> {
    setIsLoading(true);
    const request = { id, notes };
    const order = await api.approveOrder(request);
    updateOrderView(order);
    setIsLoading(false);
  }

  async function onOrderPlaced(id: number, notes: string, quantityOrdered: number, pricePaid: number): Promise<void> {
    setIsLoading(true);
    const request = { id, notes, quantity: quantityOrdered, price: pricePaid };
    const order = await api.placeOrder(request);
    updateOrderView(order);
    setIsLoading(false);
  }

  async function receiveOrder(request: OrderReceivedRequest): Promise<Order> {
    setIsLoading(true);
    const order = await api.receiveOrder(request);
    updateOrderView(order);
    setIsLoading(false);
    return order;
  }

  async function onOrderReceived(
    id: number,
    addToInventory: boolean,
    quantity: number,
    units: string,
    notes: string,
    locationId: number | null,
    storageCells: string[] | null,
    storageNotes: string | null,
    updateAfterReceive: boolean,
    delegateToId: number | null,
  ): Promise<void> {
    const request: OrderReceivedRequest = {
      id,
      addToInventory,
      quantity,
      units,
      notes,
      storageLocationId: locationId,
      storageCells,
      storageLocationNotes: storageNotes,
      delegateTo: delegateToId,
    };

    if (getIsStorageLocationRequired() && addToInventory && locationId === null) {
      // show error?
      return;
    }

    const order = await receiveOrder(request);
    if (updateAfterReceive && order && order.inventoryItemId) {
      routerNavigation.navigateTo(ApplicationPaths.Inventory.Edit(order.inventoryItemId));
    }
  }

  async function onOrderCancelled(id: number, notes: string): Promise<void> {
    setIsLoading(true);
    const request = { id, notes };
    const order = await api.cancelOrder(request);
    updateOrderView(order);
    setIsLoading(false);
  }

  function onOrderSubmitted(onFormInvalid: OnFormInvalid): void {
    const isFormValid = validateForm(onFormInvalid);

    if (isFormValid) {
      setIsUpdatingOrder(true);
      api
        .updateOrder(buildRequest(), authState.getUser())
        .then((o) => setOrderView(getOrderView(o)))
        .then(() => setIsUpdatingOrder(false))
        .then(() => setIsSuccessAlertOpen(true));
    }
  }

  function onInputChange(fieldName: string, value: string): void {
    const prevField = formInputs[fieldName];
    const field: InputState = { ...prevField, value, isValid: isValid(prevField, value) };

    const updatedFormInputs = { ...formInputs, [fieldName]: field };
    setFormInputs(updatedFormInputs);

    if (fieldName === formInputs.quantity.fieldName || fieldName === formInputs.price.fieldName) {
      setTotalPrice(Number.parseFloat(updatedFormInputs.quantity.value) * Number.parseFloat(updatedFormInputs.price.value));
    }
  }

  function buildRequest(): OrderUpdateRequest {
    return {
      id: orderId,
      catalogNumber: formInputs.catalogNumber.value,
      poNumber: formInputs.poNumber.value,
      grantNumber: formInputs.grantNumber.value,
      price: Number.parseFloat(formInputs.price.value),
      quantity: Number.parseFloat(formInputs.quantity.value),
      vendorId: Number.parseInt(formInputs.vendorId.value),
      inventoryName: formInputs.inventoryName.value,
      inventoryTypeId: Number.parseInt(formInputs.inventoryTypeId.value),
      requiredDate:
        formInputs.requiredDate.value === "" ? null : clock.midDay(moment(formInputs.requiredDate.value, clock.STANDARD_MOMENT_DATE_FORMAT).toDate()),
    };
  }

  function isValid(input: InputState, value: string): boolean {
    if (!input.required) {
      return true;
    }

    return value.trim() !== "";
  }

  function validate(input: InputState): InputState {
    if (!input.required) {
      return { ...input, isValid: true };
    }
    return { ...input, isValid: input.value.trim() !== "" };
  }

  function validateForm(onFormInvalid: OnFormInvalid): boolean {
    let isFormValid = true;
    let firstInvalid: InputState | undefined;

    const formInputsCopy = { ...formInputs };
    Object.keys(formInputs).forEach((fieldName: string) => {
      const input = validate(formInputs[fieldName]);

      if (!input.isValid) {
        if (!firstInvalid) {
          firstInvalid = input;
        }
        isFormValid = false;
        formInputsCopy[fieldName] = input;
      }
    });

    if (!isFormValid) {
      setFormInputs(formInputsCopy);
      if (firstInvalid && firstInvalid.ref.current) {
        onFormInvalid(firstInvalid.ref.current);
      }
    }
    return isFormValid;
  }

  function isModalOpen(modalId: string, openStatus: OrderStatus): boolean {
    return openModalId === modalId && orderView.status === openStatus;
  }

  function toggleModal(modalId: string): void {
    let newOpenModalId = "";
    if (modalId !== openModalId) {
      newOpenModalId = modalId;
    }
    setOpenModalId(newOpenModalId);
  }

  function getIsStorageLocationRequired(): boolean {
    return isInventoryStorageLocationRequired(inventoryTypesState, Number.parseInt(formInputs.inventoryTypeId.value));
  }

  const refreshDependencies = async (): Promise<void> => {
    await inventoryTypesState.refresh();
    await storageState.refresh();
  };

  useEffect(() => {
    refreshDependencies();

    function areDependenciesLoading(): boolean {
      return inventoryTypesState.isLoading || storageState.isLoading;
    }

    async function setInitialData(): Promise<void> {
      try {
        const order = await api.getOrder(orderId);
        let inventory: InventoryItem | undefined;
        if (order && order.sourceInventoryId) {
          inventory = await api.getInventoryItem(order.sourceInventoryId);
        }
        setSourceInventoryItem(inventory);
        const view = getOrderView(order, inventory);
        setOrderView(view);
        setFormInputs(getFormInputs(view));
        setTotalPrice(view.price * view.quantity);
        setIsLoading(false);
      } catch (error) {
        if (isNotFoundError(error)) {
          setIsLoading(false);
        } else {
          throw error;
        }
      }
    }

    if (!areDependenciesLoading()) {
      setInitialData();
    }
  }, [orderId, inventoryTypesState.isLoading, storageState.isLoading]);

  return {
    orderView,
    setOrderView,
    isLoading,
    setIsLoading,
    formInputs,
    setFormInputs,
    totalPrice,
    setTotalPrice,
    isAddingNote,
    isUpdatingOrder,
    isStorageLocationRequired: getIsStorageLocationRequired(),
    onNoteAdded,
    onOrderApproval,
    onOrderPlaced,
    onOrderReceived,
    onOrderCancelled,
    toggleAddNote,
    onOrderSubmitted,
    onInputChange,
    isModalOpen,
    toggleModal,
    openModalId,
    units: getUnits(),
    isSuccessAlertOpen,
    setIsSuccessAlertOpen,
    inventoryTypeViews: getInventoryTypeViews(inventoryTypesState),
    storageLocations: getStorageLocationView(storageState),
  };
}
