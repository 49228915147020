import React, { ReactElement } from "react";
import { Col, Row } from "reactstrap";
import { Route, Routes } from "react-router";
import { InventoryTypesProvider } from "../inventorytypes/InventoryTypesProvider";
import { RolesProvider } from "../roles/RolesProvider";
import { UsersProvider } from "../user/UsersProvider";
import { VendorsProvider } from "../vendors/VendorsProvider";
import { StorageProvider } from "../storage/StorageProvider";
import { NotificationsProvider } from "../notifications/NotificationsProvider";
import { Navigation } from "../components";
import { SubscriptionNotice } from "../subscription/notice/SubscriptionNotice";
import { MaintenanceNotice } from "../components/MaintenanceNotice/maintenanceNotice";
import { UserSpecificNotice } from "../components/UserSpecificMessage/UserSpecificNotice";
import { Logout } from "../components/Authentication/Logout";
import { SubscriptionManagement } from "../subscription/SubscriptionManagement";
import { DuplicateInventory } from "../inventory/DuplicateInventory";
import { AddInventory } from "../inventory/AddInventory";
import { UpdateInventory } from "../inventory/UpdateInventory";
import { ViewInventory } from "../inventory/ViewInventory";
import { InventorySearch } from "../inventory/search/InventorySearch";
import { OrderRequest } from "../orders/OrderRequest";
import { Order } from "../orders/Order";
import { OrderSearch } from "../orders/search/OrderSearch";
import { Management } from "../management/Management";
import { Notifications } from "../notifications/Notifications";
import { SuperUserToolsUserDetails } from "../superuser/SuperUserToolsUserDetails";
import { SuperUserToolsAccountDetails } from "../superuser/SuperUserToolsAccountDetails";
import { SuperUserTools } from "../superuser/SuperUserTools";
import { NotFound } from "../notfound/NotFound";
import { InventoryTour } from "../components/ApplicationTour/InventoryTour";
import { LoginNotificationModal } from "../components/LoginNotifications/LoginNotificationModal";
import { StorageBrowser } from "../storage/StorageBrowser";
import { Home } from "../home/Home";
import { Reports } from "../reports/Reports";
import { SessionTimeoutModal } from "../components/Authentication/SessionTimeoutModal";
import { ManageRoles } from "../roles/management/ManageRoles";
import { getManagementLinks } from "../management/ManagementLinks";
import { ApplicationPaths } from "./ApplicationPaths";

export function InventoryUserApp(): ReactElement {
  return (
    <InventoryTypesProvider>
      <RolesProvider>
        <UsersProvider>
          <VendorsProvider>
            <StorageProvider>
              <NotificationsProvider>
                <Navigation />
                <SubscriptionNotice />
                <MaintenanceNotice />
                <UserSpecificNotice />
                <main className="container-fluid" id={"main-content"} role={"main"}>
                  <Row>
                    <Col>
                      <Routes>
                        <Route path={ApplicationPaths.Routes.Home} element={<Home />} />
                        <Route path={ApplicationPaths.Routes.Logout} element={<Logout />} />
                        <Route path={ApplicationPaths.Subscription.Routes.Home} element={<SubscriptionManagement />} />
                        <Route path={ApplicationPaths.Inventory.Routes.Duplicate} element={<DuplicateInventory />} />
                        <Route path={ApplicationPaths.Inventory.Routes.Add} element={<AddInventory />} />
                        <Route path={ApplicationPaths.Inventory.Routes.Edit} element={<UpdateInventory />} />
                        <Route path={ApplicationPaths.Inventory.Routes.Item} element={<ViewInventory />} />
                        <Route path={ApplicationPaths.Inventory.Routes.Search} element={<InventorySearch />} />
                        <Route path={ApplicationPaths.Orders.Routes.ReorderItem} element={<OrderRequest />} />
                        <Route path={ApplicationPaths.Orders.Routes.Request} element={<OrderRequest />} />
                        <Route path={ApplicationPaths.Orders.Routes.Order} element={<Order />} />
                        <Route path={ApplicationPaths.Orders.Routes.Search} element={<OrderSearch />} />
                        <Route path={ApplicationPaths.Storage.Routes.Home} element={<StorageBrowser />} />
                        <Route path={ApplicationPaths.Storage.Routes.Browse} element={<StorageBrowser />} />
                        <Route path={ApplicationPaths.Management.Routes.Home} element={<Management />}>
                          {getManagementLinks().map((link) => (
                            <Route index={link.isIndex} key={link.path} path={link.path} element={<link.component />} />
                          ))}
                          <Route path={ApplicationPaths.Management.Routes.RoleById} element={<ManageRoles />} />
                        </Route>
                        <Route path={ApplicationPaths.Notifications.Routes.Home} element={<Notifications />} />
                        <Route path={ApplicationPaths.SuperUser.Routes.User} element={<SuperUserToolsUserDetails />} />
                        <Route path={ApplicationPaths.SuperUser.Routes.Account} element={<SuperUserToolsAccountDetails />} />
                        <Route path={ApplicationPaths.SuperUser.Routes.Home} element={<SuperUserTools />} />
                        <Route path={ApplicationPaths.Reports.Home} element={<Reports />} />
                        <Route path={"*"} element={<NotFound />} />
                      </Routes>
                    </Col>
                  </Row>
                  <InventoryTour />
                  <LoginNotificationModal />
                  <SessionTimeoutModal />
                </main>
              </NotificationsProvider>
            </StorageProvider>
          </VendorsProvider>
        </UsersProvider>
      </RolesProvider>
    </InventoryTypesProvider>
  );
}
