import React, { ReactElement } from "react";
import { Route, Routes } from "react-router";
import { UnauthenticatedRedirectApp } from "./UnauthenticatedRedirectApp";
import { HealthComponent } from "./HealthComponent";

export function UnauthenticatedApp(): ReactElement {
  return (
    <Routes>
      <Route path={"/health"} element={<HealthComponent />} />
      <Route path={"*"} element={<UnauthenticatedRedirectApp />} />
    </Routes>
  );
}
