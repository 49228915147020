import React, { ReactElement, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Badge, Col, Row } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons/faPlusCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import { Loading } from "../../components";
import { InventoryTypesContext } from "../InventoryTypesContext";
import { parseIntOrUndefined } from "../../utils";
import { UsersContext } from "../../user/UsersContext";
import { getInventoryTypeFromView } from "../selectors";
import { useRouterNavigation } from "../../utils/useRouterNavigation";
import { ManageInventoryType } from "./ManageInventoryType";
import { AddInventoryType } from "./AddInventoryType";
import { ManageInventoryTypesList } from "./ManageInventoryTypesList";
import { ManageInventoryTypesProps, useManageInventoryTypes } from "./ManageInventoryTypesHooks";

type RouteParams = {
  id: string;
};

function getManageInventoryTypesProps(id: string): ManageInventoryTypesProps {
  return {
    isAdding: id === "add",
    id: parseIntOrUndefined(id),
  };
}

export function ManageInventoryTypes(): ReactElement {
  const { id } = useParams<RouteParams>();
  const {
    activeInventoryTypeId,
    isAdding,
    isLoading,
    inventoryTypeViews,
    onCancelAddType,
    onCancelUpdateType,
    onShowType,
    onDuplicateType,
    onShowAddType,
    onAddType,
    onUpdateType,
    onDeleteType,
    onAttributeAdded,
    isNameValid,
    isColorDuplicated,
  } = useManageInventoryTypes(
    getManageInventoryTypesProps(id || ""),
    useContext(InventoryTypesContext),
    useContext(UsersContext),
    useRouterNavigation(),
  );

  const intl = useIntl();

  if (isLoading) {
    return <Loading />;
  }

  function showAdd(): boolean {
    return isAdding && !activeInventoryTypeId;
  }

  function showUpdate(): boolean {
    return !isAdding && activeInventoryTypeId !== undefined;
  }

  function showList(): boolean {
    return !isAdding && !activeInventoryTypeId;
  }

  return (
    <div>
      <div className="management-header">
        <div className={"d-flex align-items-center"}>
          <h1 className="inventory-heading">
            <FormattedMessage id="types" />
          </h1>
          <Badge role={"status"} aria-label={intl.formatMessage({ id: "manage.inventory.types.badge" }, { count: inventoryTypeViews.length })}>
            {inventoryTypeViews.length}
          </Badge>
        </div>
        <Button className="management-header-action" color="link" onClick={onShowAddType} data-tour="add-inventory-types">
          <FontAwesomeIcon icon={faPlusCircle} /> <FormattedMessage id="add.new.type" />
        </Button>
      </div>
      <Row>
        <Col>
          {showList() && (
            <ManageInventoryTypesList
              onShowType={onShowType}
              onDuplicateType={onDuplicateType}
              inventoryTypeViews={inventoryTypeViews}
              onDeleted={onDeleteType}
              isColorDuplicated={isColorDuplicated}
              onSaveInventoryType={(type) => onUpdateType(getInventoryTypeFromView(type))}
            />
          )}
          {showAdd() && <AddInventoryType onTypeAdded={onAddType} onCancelTypeAdded={onCancelAddType} isNameValid={isNameValid} />}
          {showUpdate() && (
            <ManageInventoryType
              inventoryTypeView={inventoryTypeViews.find((t) => t.id === activeInventoryTypeId)}
              onUpdated={onUpdateType}
              onAttributeAdded={onAttributeAdded}
              onCancel={onCancelUpdateType}
              isNameValid={isNameValid}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}
