import { useEffect, useState } from "react";
import { UserSpecificMessage } from "@labarchives/inventory-shared/build/inventory";
import { InventoryApi } from "../../api/InventoryApi";
import * as inventoryLocalStorage from "../../api/inventoryLocalStorage";
import { isNotFoundError } from "../../utils/errorHelpers";

export interface UserSpecificNoticeHooks {
  message: string | undefined;

  onDismiss(): void;
}

export function useUserSpecificNotice(api: InventoryApi): UserSpecificNoticeHooks {
  const [message, setMessage] = useState<UserSpecificMessage | undefined>();

  useEffect(() => {
    api
      .getUserSpecificMessage()
      .then((m) => {
        if (m && !inventoryLocalStorage.isUserNoticeDismissed(m.id)) {
          setMessage(m);
        } else {
          setMessage(undefined);
        }

        return m;
      })
      .catch((error) => {
        if (isNotFoundError(error)) {
          setMessage(undefined);
        } else {
          api.logError(error);
        }
      });
  }, []);

  function onDismiss(): void {
    if (message) {
      inventoryLocalStorage.dismissUserNotice(message.id);
      setMessage(undefined);
    }
  }

  return {
    message: message ? message.text : undefined,
    onDismiss,
  };
}
