import { useNavigate, useLocation } from "react-router";

export interface RouterNavigationHooks {
  navigateTo(path: string, previousLocation?: Location): void;
  getPreviousLocation(): Location | undefined;
}

export function useRouterNavigation(): RouterNavigationHooks {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateTo = (path: string, previousLocation?: Location): void => {
    if (previousLocation) {
      navigate(path, { state: { prevLocation: previousLocation } });
    } else {
      navigate(path, { state: { prevLocation: location } });
    }
  };

  function getPreviousLocation(): Location | undefined {
    return location?.state?.prevLocation;
  }
  return {
    navigateTo,
    getPreviousLocation,
  };
}
