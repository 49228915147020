import { Role } from "@labarchives/inventory-shared/build/inventory";
import { useState } from "react";
import { RoleView } from "../types/views";
import { RoleState } from "../types/state";
import { getRoleViews } from "../selectors";
import { UserState } from "../../user/types/state";
import { InventoryApi } from "../../api/InventoryApi";
import { RouterNavigationHooks } from "../../utils/useRouterNavigation";

export interface ManageRolesHooks {
  roleViews: RoleView[];
  isAdding: boolean;
  isEditing: boolean;
  isDeleting: boolean;
  isDeleteAllowed: boolean;
  isDeleteFailed: boolean;
  activeRoleView?: RoleView;

  onAddRoleToggle(): void;

  onEditToggle(roleView: RoleView): void;

  onDeleteToggle(roleView: RoleView): void;

  onRoleUpdated(role: Role): void;

  onRoleAdded(role: Role): void;

  onRoleDeleted(role: Role): Promise<void>;

  isRoleValid(role: Role): boolean;
}

interface ManageRolesProps {
  roleState: RoleState;
  userState: UserState;
  api: InventoryApi;
  isAdding: boolean;
  routerNavigation: RouterNavigationHooks;
}

export function useManageRoles(props: ManageRolesProps): ManageRolesHooks {
  const [activeRoleView, setActiveRoleView] = useState<RoleView | undefined>();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteAllowed, setIsDeleteAllowed] = useState(false);
  const [isDeleteFailed, setIsDeleteFailed] = useState(false);
  const { routerNavigation, isAdding, roleState, userState, api } = props;

  function onAddRoleToggle(): void {
    if (isAdding) {
      routerNavigation.navigateTo(`/management/roles`);
    } else {
      setActiveRoleView(undefined);
      routerNavigation.navigateTo(`/management/roles/add`);
    }
  }

  function onEditToggle(roleView: RoleView): void {
    const toggledEditState = !isEditing;
    setActiveRoleView(toggledEditState ? roleView : undefined);
    setIsEditing(toggledEditState);
  }

  async function onRoleAdded(role: Role): Promise<void> {
    await roleState.onRoleAdded(role);
    routerNavigation.navigateTo(`/management/roles`);
  }

  async function onRoleUpdated(role: Role): Promise<void> {
    await roleState.onRoleUpdated(role);
    setIsEditing(false);
    setActiveRoleView(undefined);
  }

  function onDeleteToggle(roleView: RoleView): void {
    setIsDeleteFailed(false);

    const toggledDeleteState = !isDeleting;
    if (toggledDeleteState) {
      const userHasRole = userState.allIds.some((id) => userState.byId[id].roleId === roleView.id);
      setIsDeleteAllowed(!userHasRole);
    }

    setIsDeleting(toggledDeleteState);
    setActiveRoleView(toggledDeleteState ? roleView : undefined);
  }

  async function onRoleDeleted(role: Role): Promise<void> {
    try {
      await roleState.onRoleDeleted(role.id);
      setIsDeleting(false);
      setActiveRoleView(undefined);
    } catch (error) {
      api.logError(error);
      setIsDeleteFailed(true);
    }
  }

  function isRoleValid(role: Role): boolean {
    if (role.name.trim() === "") {
      return false;
    }

    const allRoles = roleState.allIds.map((id) => roleState.byId[id]);
    const isDuplicate = allRoles.some((r) => r.id !== role.id && r.name.trim().toLocaleLowerCase() === role.name.trim().toLocaleLowerCase());

    return !isDuplicate;
  }

  return {
    roleViews: getRoleViews(roleState),
    isAdding: isAdding,
    isEditing,
    isDeleting,
    isDeleteAllowed,
    isDeleteFailed,
    activeRoleView,
    onAddRoleToggle,
    onEditToggle,
    onRoleUpdated,
    onRoleAdded,
    onDeleteToggle,
    onRoleDeleted,
    isRoleValid,
  };
}
