import React, { ReactElement, useState } from "react";
import { UserAutoSuggestItem } from "@labarchives/inventory-shared/build/inventory";
import Autosuggest, { RenderSuggestionParams, SuggestionsFetchRequestedParams } from "react-autosuggest";

export interface InviteUsersAutoSuggestProps {
  id: string;
  options: UserAutoSuggestItem[];

  onChange(value: string): void;

  invalid: boolean;
  autoFocus?: boolean;
}

export function InviteUsersAutoSuggest(props: InviteUsersAutoSuggestProps): ReactElement {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState<UserAutoSuggestItem[]>([]);

  const onChange = (event: never, { newValue }: { newValue: string }): void => {
    setInputValue(newValue);
    props.onChange(newValue);
  };

  const inputProps = {
    placeholder: "colleague@email.com",
    onChange,
    type: "email",
    value: inputValue,
    invalid: props.invalid.toString(),
    autoFocus: props.autoFocus,
    className: "form-control",
  };

  const getSuggestionValue = (suggestion: UserAutoSuggestItem): string => suggestion.email;

  const renderSuggestion = (suggestion: UserAutoSuggestItem, { query }: RenderSuggestionParams): ReactElement => {
    const highlighted = suggestion.email.replace(new RegExp(`(.*)(${query})(.*)`, "ig"), "$1<strong>$2</strong>$3");
    const userName = `${suggestion.firstName} ${suggestion.lastName}`;
    return <span dangerouslySetInnerHTML={{ __html: `${highlighted} (${userName})` }} />;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderInputComponent = (inputComponentProps: any): ReactElement => {
    const { ...other } = inputComponentProps;

    return <input className={`form-control me-sm-2 ${props.invalid ? "is-invalid" : ""}`} {...other} />;
  };

  const filterSuggestions = (value: string): UserAutoSuggestItem[] => {
    const trimmedValue = value.trim().toLowerCase();
    return props.options.filter((v) => v.email.toLowerCase().includes(trimmedValue));
  };

  const onSuggestionsFetchRequested = ({ value }: SuggestionsFetchRequestedParams): void => {
    setSuggestions(filterSuggestions(value));
  };

  const onSuggestionsClearRequested = (): void => {
    setSuggestions([]);
  };

  return (
    <Autosuggest
      id={props.id}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      // @ts-ignore
      inputProps={inputProps}
      renderInputComponent={renderInputComponent}
    />
  );
}
