import { FormattedDate, FormattedNumber } from "react-intl";
import React, { ReactElement, useContext } from "react";
import { Badge, Col, Input, Label, Row, Table } from "reactstrap";
import { useParams } from "react-router";
import { OrganizationBillingType, SubscriptionTransactionAction, SubscriptionType, UserStatus } from "@labarchives/inventory-shared/build/inventory";
import { Link } from "react-router";
import { AlertWithIcon, Button, Card, CardBody, CardHeader } from "@labarchives/ui-design/dist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-light-svg-icons";
import { Settings } from "../utils/Settings";
import { InventoryApiClient } from "../api";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { Loading } from "../components";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { SuperUpdateSubscriptionModal } from "./SuperUserUpdateSubscriptionModal";
import { SuperUserTransferOwnershipModal } from "./SuperUserTransferOwnershipModal";
import { useSuperUserAccountDetails } from "./SuperUserAccountDetailsHooks";
import "./SuperUserToolsAccountDetails.scss";
import { SuperUserAddUserModal } from "./SuperUserAddUserModal";
import { SuperUserAccountDetailsView } from "./views";
import { SuperUserBundleSubscriptionModal } from "./SuperUserBundleSubscriptionModal";

type RouteParams = {
  id: string;
};

export function SuperUserToolsAccountDetails(): ReactElement {
  const { id } = useParams<RouteParams>();
  const authState = useContext(AuthenticationContext);
  const api = new InventoryApiClient();

  const accountId = Number.parseInt(id || "");
  const {
    isLoading,
    accountNotFound,
    accountDetails,
    onSubscriptionUpdate,
    isEditing,
    setIsEditing,
    isTransferring,
    setIsTransferring,
    isUpdateError,
    setIsUpdateError,
    isAddingUser,
    setIsAddingUser,
    isSearchingUsers,
    userSearchResults,
    historyUsers,
    showSubscriptionDetails,
    filteredActivityHistory,
    isBundling,
    onFilterActivityHistory,
    onOwnerChanged,
    onNotesChanged,
    onNotesSaved,
    onUserAdded,
    onSearchUsers,
    setIsBundling,
    onSubscriptionBundled,
  } = useSuperUserAccountDetails(accountId, api);

  const isBundleEnabled = Settings.getBundleProductCode() !== "";

  const user = authState.getUser();
  if (!user || !user.isSuperUser) {
    return <></>;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (accountNotFound) {
    return <div>This user does not have an inventory account</div>;
  }

  function getSubscriptionType(type: SubscriptionType): string {
    if (type === SubscriptionType.Free) {
      return "Free Trial";
    }

    return "Paid Subscription";
  }

  function getTransactionAction(action: SubscriptionTransactionAction): string {
    if (action === SubscriptionTransactionAction.Renewal) {
      return "Renewal";
    }
    if (action === SubscriptionTransactionAction.AdditionalUsers) {
      return "Additional Users";
    }

    return "New Subscription";
  }

  function onShowTransfer(): void {
    setIsTransferring(true);
  }

  function getLabArchivesSuperUserLink(account: SuperUserAccountDetailsView): string {
    return `${Settings.getLabArchivesSuperUserBaseUrl()}/user/${account.ownerId}`;
  }

  return (
    <div>
      <AlertWithIcon color="danger" isOpen={isUpdateError} toggle={() => setIsUpdateError(false)}>
        There was an error updating the subscription details
      </AlertWithIcon>
      <Row>
        <Col>
          <div>
            <Link to={ApplicationPaths.SuperUser.Home}>Return to Super User Inventory Dashboard</Link>
          </div>

          <Card>
            <CardHeader>{accountDetails.name}</CardHeader>
            <CardBody className="superuser-account-details">
              <Row>
                <Col>
                  <div className="superuser-account-common-detail">
                    <span>
                      Created On: <FormattedDate value={accountDetails.dateCreated} />
                    </span>
                    <span>
                      Account Owner: <Link to={ApplicationPaths.SuperUser.User(accountDetails.ownerId)}>{accountDetails.ownerName}</Link> (
                      {accountDetails.ownerEmail})
                      {!accountDetails.isBundleSubscription && (
                        <Button color={"link"} onClick={onShowTransfer}>
                          Transfer
                        </Button>
                      )}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={9}>
                  <div className="superuser-account-subscription-details superuser-account-section">
                    <div>
                      {accountDetails.hasSiteLicense && (
                        <div>
                          This account is part of the site license for <strong>{accountDetails.siteLicenseName}</strong>
                        </div>
                      )}
                      {!accountDetails.hasSiteLicense && (
                        <div>
                          <div className={"d-flex align-items-center mb-2"}>
                            <div className="superuser-account-section-title">Active Subscription Details</div>
                            <div>
                              {!accountDetails.isBundleSubscription && (
                                <span className="superuser-account-section-title-edit">
                                  <Button color="link" onClick={() => setIsEditing(true)}>
                                    Edit
                                  </Button>
                                  {isBundleEnabled && (
                                    <>
                                      <span> | </span>

                                      <Button color={"link"} onClick={() => setIsBundling(true)}>
                                        Convert to Bundle
                                      </Button>
                                    </>
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                          <div>
                            Valid: <FormattedDate value={accountDetails.subscriptionBegin} timeZone={"UTC"} /> -{" "}
                            <FormattedDate value={accountDetails.subscriptionEnd} timeZone={"UTC"} />
                          </div>
                          <div>Type: {getSubscriptionType(accountDetails.subscriptionType)}</div>
                          <div>Paid Seat Count: {accountDetails.subscriptionSeatCount}</div>
                          <div>Billing Type: {accountDetails.billingType === OrganizationBillingType.Commercial ? "Commercial" : "Academic"}</div>
                          {accountDetails.isBundleSubscription && (
                            <div>
                              Has ELN+Inv Bundle{" "}
                              <a href={getLabArchivesSuperUserLink(accountDetails)} target={"_blank"} rel="noreferrer">
                                Bundle Details <FontAwesomeIcon icon={faExternalLink} />
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="superuser-account-subscription-details-stat">
                        <span>Items</span>
                        <div>{accountDetails.numberOfItems}</div>
                      </div>
                      <div className="superuser-account-subscription-details-stat">
                        <span>Orders</span>
                        <div>{accountDetails.numberOfOrders}</div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="superuser-account-section">
                      <div className="superuser-account-section-title">Notes</div>
                      <div>
                        <Label for="notes" className={"visually-hidden"}>
                          Notes
                        </Label>
                        <Input id="notes" type="textarea" value={accountDetails.notes} onChange={(e) => onNotesChanged(e.target.value)} />
                      </div>
                      <div className={"mt-1 d-flex justify-content-end"}>
                        <Button color={"primary"} onClick={() => onNotesSaved()} type={"button"}>
                          Save Notes
                        </Button>
                      </div>
                    </div>

                    <div className="superuser-account-section">
                      <div className="superuser-account-section-title">
                        Users <Badge>{accountDetails.users.length}</Badge>
                      </div>
                      <Table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Role</th>
                          </tr>
                        </thead>
                        <tbody>
                          {accountDetails.users.map((u) => (
                            <tr key={`${u.id} ${u.fullName}`}>
                              <td>
                                {u.isLinkable && <Link to={ApplicationPaths.SuperUser.User(u.id)}>{u.fullName}</Link>}
                                {!u.isLinkable && <span>{u.fullName}</span>}
                              </td>
                              <td>{u.email}</td>
                              <td>
                                {u.status === UserStatus.Active && "Active"}
                                {u.status === UserStatus.Inactive && "Inactive"}
                              </td>
                              <td>{u.roleName}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      <div className={"mt-1 d-flex justify-content-end"}>
                        <Button color={"primary"} onClick={() => setIsAddingUser(true)}>
                          Add User
                        </Button>
                      </div>
                    </div>

                    {showSubscriptionDetails && (
                      <div className="superuser-account-section">
                        <div className="superuser-account-section-title">
                          All Subscription Terms <Badge>{accountDetails.subscriptionHistory.length}</Badge>
                        </div>
                        <Table>
                          <thead>
                            <tr>
                              <th>Begin</th>
                              <th>End</th>
                              <th>Type</th>
                              <th>Seat Count</th>
                            </tr>
                          </thead>
                          <tbody>
                            {accountDetails.subscriptionHistory.map((h) => (
                              <tr key={h.id}>
                                <td>
                                  <FormattedDate value={h.begin} />
                                </td>
                                <td>
                                  <FormattedDate value={h.end} />
                                </td>
                                <td>{getSubscriptionType(h.type)}</td>
                                <td>{h.seatCount}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    )}

                    {showSubscriptionDetails && (
                      <div className="superuser-account-section">
                        <div className="superuser-account-section-title">
                          Transaction History <Badge>{accountDetails.transactionHistory.length}</Badge>
                        </div>
                        <Table>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Activity</th>
                              <th>Total</th>
                              <th>Cost Per Seat</th>
                              <th>Number Of Seats</th>
                            </tr>
                          </thead>
                          <tbody>
                            {accountDetails.transactionHistory.map((h) => (
                              <tr key={h.transactionId} id={h.transactionId}>
                                <td>
                                  <FormattedDate value={h.date} />
                                </td>
                                <td>{getTransactionAction(h.action)}</td>
                                <td>
                                  <FormattedNumber value={h.totalPrice} style="currency" currency="USD" />
                                </td>
                                <td>
                                  <FormattedNumber value={h.perUserPrice} style="currency" currency="USD" />
                                </td>
                                <td>{h.seatCount}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </div>
                </Col>
                <Col>
                  <div className="superuser-account-activity-history superuser-account-section">
                    <div className="superuser-account-section-title">
                      Activity <Badge>{filteredActivityHistory.length}</Badge>
                    </div>
                    <div className="superuser-account-activity-history-filter">
                      <div>{historyUsers.length} users</div>
                      <div>
                        <Input bsSize="sm" type="select" onChange={(e) => onFilterActivityHistory(e.target.value)}>
                          <option value="">All Users</option>
                          {historyUsers.map((u) => (
                            <option key={u} value={u}>
                              {u}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </div>
                    <div>
                      {filteredActivityHistory.map((h) => (
                        <div key={h.id} id={h.id} className="superuser-account-activity-history-item">
                          <div className="superuser-account-activity-history-item-head">
                            <span className="superuser-account-activity-history-item-date">
                              <FormattedDate value={h.date} day="numeric" month="numeric" year="numeric" hour="numeric" minute="numeric" />
                            </span>
                            <span className="superuser-account-activity-history-item-user">
                              {h.isLinkable && <Link to={ApplicationPaths.SuperUser.User(h.userId)}>{h.userName}</Link>}
                              {!h.isLinkable && <span>{h.userName}</span>}
                            </span>
                          </div>
                          <div className="superuser-account-activity-history-item-description">{h.description}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <SuperUpdateSubscriptionModal
        isOpen={isEditing}
        account={accountDetails}
        onToggle={() => setIsEditing(false)}
        onUpdate={onSubscriptionUpdate}
      />
      <SuperUserTransferOwnershipModal
        isOpen={isTransferring}
        account={accountDetails}
        onToggle={() => setIsTransferring(false)}
        onUpdate={onOwnerChanged}
      />
      <SuperUserAddUserModal
        isOpen={isAddingUser}
        account={accountDetails}
        onToggle={() => setIsAddingUser(false)}
        onUserAdded={onUserAdded}
        isSearching={isSearchingUsers}
        onSearchUsers={onSearchUsers}
        userSearchResults={userSearchResults}
      />
      <SuperUserBundleSubscriptionModal
        isOpen={isBundling}
        accountDetails={accountDetails}
        onToggle={() => setIsBundling(false)}
        onSubscriptionUpdated={onSubscriptionBundled}
        api={api}
      />
    </div>
  );
}
