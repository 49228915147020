import { StorageLocation } from "@labarchives/inventory-shared/build/inventory";
import { StorageLocationTreeView } from "../types/views";
import { StorageState } from "../types/state";
import { getStorageLocationView } from "../selectors";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import { RouterNavigationHooks } from "../../utils/useRouterNavigation";
import { isLocationNameDuplicated } from "./isLocationNameDuplicated";

export interface ManageStorageHooks {
  isLoading: boolean;
  storageLocations: StorageLocationTreeView[];
  isAdding: boolean;
  activeLocationId?: number;

  onAddLocation(location: StorageLocation): void;
  onUpdateLocation(location: StorageLocation): void;
  onDeleteLocation(location: StorageLocation): void;
  onShowAddLocation(): void;
  onShowEditLocation(location: StorageLocationTreeView): void;
  onCancelAdd(): void;
  onDuplicateStorageLocation(location: StorageLocationTreeView): Promise<boolean>;
}

export function useManageStorage(props: ManageStorageProps, storageState: StorageState, routeNavigation: RouterNavigationHooks): ManageStorageHooks {
  const navigate = routeNavigation;
  function onShowAddLocation(): void {
    navigate.navigateTo(ApplicationPaths.Management.StorageAdd);
  }

  function onShowEditLocation(storageLocation: StorageLocationTreeView): void {
    const { id } = storageLocation.location;
    navigate.navigateTo(ApplicationPaths.Management.StorageLocation(id));
  }

  function onAddLocation(storageLocation: StorageLocation): void {
    storageState.onLocationAdded(storageLocation).then((newLocation) => {
      if (props.isAdding) {
        navigate.navigateTo(ApplicationPaths.Management.StorageLocation(newLocation.id));
      }
      return newLocation;
    });
  }

  function onUpdateLocation(storageLocation: StorageLocation): void {
    storageState.onLocationUpdated(storageLocation).then((updated) => {
      // show a message?
      return updated;
    });
  }

  function onDeleteLocation(storageLocation: StorageLocation): void {
    // eslint-disable-next-line promise/always-return
    storageState.onLocationDeleted(storageLocation).then(() => {
      navigate.navigateTo(ApplicationPaths.Management.Storage);
    });
  }

  function onCancelAdd(): void {
    navigate.navigateTo(ApplicationPaths.Management.Storage);
  }

  function copyName(name: string): string {
    const copiedName = `${name} (copy)`;
    if (isLocationNameDuplicated(getStorageLocationView(storageState), copiedName, 0, null)) {
      return copyName(copiedName);
    }
    return copiedName;
  }

  async function copyTree(location: StorageLocationTreeView, parentId: number | null): Promise<number> {
    const newLocation: StorageLocation = {
      id: 0,
      parentId,
      name: location.location.name,
      numberOfRows: location.location.numberOfRows,
      numberOfColumns: location.location.numberOfColumns,
      freezerBoxDisplayFormat: location.location.freezerBoxDisplayFormat,
    };

    if (!newLocation.parentId) {
      newLocation.name = copyName(newLocation.name);
    }

    const addedLocation = await storageState.onLocationAdded(newLocation).catch(() => newLocation);

    const promises: Promise<number>[] = [];

    if (addedLocation.id !== 0) {
      location.children.forEach((child) => {
        promises.push(copyTree(child, addedLocation.id));
      });
    }

    return Promise.all(promises).then(async () => {
      await storageState.refresh(true);
      return addedLocation.id;
    });
  }

  async function onDuplicateStorageLocation(storageLocation: StorageLocationTreeView): Promise<boolean> {
    const newId = await copyTree(storageLocation, null);
    if (newId === 0) {
      return false;
    }
    navigate.navigateTo(ApplicationPaths.Management.StorageLocation(newId));
    return true;
  }

  return {
    isLoading: storageState.isLoading,
    storageLocations: getStorageLocationView(storageState).asTree(),
    isAdding: props.isAdding,
    activeLocationId: props.id,
    onShowAddLocation,
    onShowEditLocation,
    onAddLocation,
    onUpdateLocation,
    onDeleteLocation,
    onCancelAdd,
    onDuplicateStorageLocation,
  };
}

export interface ManageStorageProps {
  id?: number;
  isAdding: boolean;
}
